import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'

export default function FormInput({ propName, required, inputProps, containerClassName, alwaysFocus, title, type = "text", subText, children, ...etc }) {
    const { isSubmitting, values, handleChange, errors, handleSubmit } = useFormikContext();
    const navigator = useMemo(() => propName ?? title, [])
    const invalid = useMemo(() => errors && errors[navigator], [errors])
    const [isFoucsed, setIsFoucsed] = useState(false);
    return (
        <div className={`input-group input-group-outline mb-3 ${(alwaysFocus || isFoucsed || values[navigator] ? 'is-focused' : '')} ${invalid ? 'is-invalid' : values[navigator] ? 'is-valid' : ''}`}>
            <label className='form-label'>{title} {required && <span className='mx-1 text-danger'>*</span>}</label>
            <input onKeyDown={({ key }) => {
                if (key === 'Enter') handleSubmit();
            }} type={type} className={`form-control ${type === 'date' && !values[navigator] ? 'color-transparent' : ''}`} disabled={isSubmitting} onChange={handleChange(navigator)} value={(values[navigator] ?? "")}
                onFocus={e => setIsFoucsed(true)}
                onBlur={e => setIsFoucsed(false)}
                {...inputProps} {...etc}
            />
            {invalid && <div className="invalid-feedback">
                {errors[navigator]}
            </div>}
            {children}
        </div>
    )
}
