import React, { useMemo, useReducer } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import FormInput from '../components/FormInput';
import FormCheckBox from '../components/FormCheckBox';
import { CheckCell, getRapidTheme } from '../Utlities/functions';
import FormSelect from './../components/FormSelect';
import { getText, languageState } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';

// validation schema

//"Type", "Ar","En","Disable"
// table columns


//     user.Id,
//    user.Name,
//    user.Deleted,
//    user.Email,
//    user.PhoneNumber,
//    user.LockoutEnabled,
//    Role = role.Name
const FormContent = ({ values }) => {
    const isNew = useMemo(() => values.Id == "", [])
    return (
        <>
            <div className="col-12"><FormInput required propName="Name" title={getText('name')} /></div>
            <div className="col-12"><FormInput required propName="Email" title={getText('email')} type="email" /></div>
            <div className="col-12"><FormInput propName="PhoneNumber" title={getText('phone')} maxLength={10} /></div>
            <div className="col-12">
                <FormSelect propName="Role" title={getText('role')} containerClassName="col-md-6">
                    <option></option>
                    <option value="Admin">{getText('admin')}</option>
                    <option value="Editor">{getText('editor')}</option>
                    <option value="Read">{getText('reader')}</option>
                </FormSelect>
            </div>
            <div className="col-12"><FormCheckBox propName="LockoutEnabled" title={getText('lockedenabled')} /></div>

        </>
    )
}
const theme = "warning";
export default function UsersPage() {
    const lng = useRecoilValue(languageState);
    const { title, columns, validationSchema } = useMemo(() => {
        const title = getText("users");
        const columns = [
            { Header: getText("name"), accessor: 'Name', isSorted: true },
            { Header: getText("email"), accessor: 'Email' },
            { Header: getText("phone"), accessor: 'PhoneNumber' },
            { Header: getText("role"), accessor: 'Role', disableSortBy: true, },
            { Header: getText("lockedenabled"), accessor: 'LockoutEnabled', Cell: CheckCell },
        ]
        const validationSchema = Yup.object().shape({
            Name: Yup.string().required(getText("requiredf")).max(200),
            Email: Yup.string().email(getText("invalidvalue")).required(getText("requiredf")).max(256),
            PhoneNumber: Yup.string().required(getText("requiredf")).matches(
                /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                getText("invalidphone")
            ),
            Role: Yup.string().required(getText('mustselectrole'))
        })
        return { title, columns, validationSchema }
    }, [lng]);

    return (
        <DHead title={title} theme={theme}>
            <DefaultTable
                title={title}
                url="/api/users"
                deleteUrl="/api/users/deleteuser"
                theme={theme}
                columns={columns}
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{ Id: 0, Ar: "", En: "", Disable: false }}
                editorProps={{
                    size: "md"
                }}
            />
        </DHead>
    )
}
