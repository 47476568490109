import React from 'react'
import Footer from './Footer'
import { getText } from '../Utlities/app-store'

export default function FooterInfo() {
    return (
        <div className="h-25 bg-info text-white mt-7">
            <div className="container pt-6">
                <div className="row jrtl">
                    <div className="col-sm-4">
                        <h6 className='text-white fs-5'>{getText('yus')}</h6>
                        <div className='text-sm'>{getText('yusdesc')}</div>
                    </div>

                    <div className="col-sm-4">

                    </div>
                    <div className="col-sm-4">
                        <h6 className='text-white fs-4'>{getText('beintouch')}</h6>
                        <div className='text-sm'>
                            <div><i className="fa-solid fa-location-dot mx-1"></i> <a href="https://maps.app.goo.gl/gE2hT8w6R7ZsBMSc7?g_st=iw" target="_blank" rel="noopener noreferrer" className='text-white'>{getText('touchlocation')}</a></div>
                            {/* <div className='my-1'><i className="fa-solid fa-phone mx-1"></i><a href="tel:+966505934771" className='text-white' target="_blank" rel="noopener noreferrer">966505934771</a></div> */}
                            <div><i className="fa-solid fa-at mx-1"></i> <a href="mailto:dar.alnokba@gmail.com" className='text-white'>dar.alnokba@gmail.com</a> </div>
                        </div>
                    </div>
                </div>
                <hr className='light horizontal' />
            </div>
            <Footer />
        </div>
    )
}
