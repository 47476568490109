import { useFormikContext } from 'formik'
import React from 'react'

export default function FormCheckBox({ propName, inputProps, containerClassName, title }) {
    const { isSubmitting, values, setFieldValue } = useFormikContext();
    return (
        <div className={`form-check ${containerClassName ?? ""}`}>
            <input className="form-check-input mt-0" type="checkbox" disabled={isSubmitting} onChange={e => {
                setFieldValue(propName ?? title, e.target.checked)
                // handleChange(propName ?? title)
            }} checked={values[propName ?? title]} {...inputProps} />
            <label className="custom-control-label user-select-none mb-0" onClick={() => setFieldValue(propName ?? title, !values[propName ?? title])}>{title}</label>
        </div>

    )
}
