import React, { useState } from 'react'
import DHead from '../components/DHead'
import { getText } from '../Utlities/app-store'
import { CardButton } from './FiltersPage'
import { getRapidTheme } from '../Utlities/functions'
import BModel from '../components/BModel'
import TravelReport from './Reports/TravelReport'

const listOfReports = [
    { title: 'travelReport', description: 'travelReportDescription', component: <TravelReport /> }
]

export default function ReportsPage() {
    const [report, setReport] = useState(null)
    return (
        <>
            <DHead title={getText('reports')}>
                <div className="p-3">
                    <div className="row">
                        <CardButton title={getText('travelReport')} decription={getText('travelReportDescription')} iconClass="fa-solid fa-plane-arrival" theme={getRapidTheme(0)} onClick={() => setReport(listOfReports[0])} />
                    </div>
                </div>
            </DHead>
            {report && <BModel cancelText={getText('close')} size='lg' title={getText(report.title)} show={report != null} setShow={() => setReport(null)}>
                {report.component}
            </BModel>}
        </>
    )
}
