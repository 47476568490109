import React, { useMemo, useReducer } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import FormInput from '../components/FormInput';
import FormCheckBox from '../components/FormCheckBox';
import { getRapidTheme } from '../Utlities/functions';
import { getText, languageState } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';

// validation schema

//"Type", "Ar","En","Disable"
// table columns

const FormContent = () => {
    return (
        <>
            <div className="col-12"><FormInput required propName="Ar" title={`${getText('name')} - ${getText('arabic')}`} /></div>
            <div className="col-12"><FormInput required propName="En" title={`${getText('name')} - ${getText('english')}`} /></div>
            <div className="col-12"><FormCheckBox propName="Disable" title={getText('disabled')} /></div>
        </>
    )
}
export default function FilterItemsPage({ Type = 0 }) {
    const lng = useRecoilValue(languageState)
    const settings = useMemo(() => {
        const theme = getRapidTheme(Type);
        const title = `${Type == 0 ? "countries" : Type == 1 ? "jobs" : Type == 2 ? "categories" : Type == 3 ? "experiances" : Type == 4 ? "religions" : Type == 5 ? "statuses" : "followup"}`;
        const url = `/api/${title}`;
        return { theme, url, title }
    }, [Type]);
    const { validationSchema, columns } = useMemo(() => {
        const validationSchema = Yup.object().shape({
            Ar: Yup.string().required(getText('requiredf')),
            En: Yup.string().required(getText('requiredf')),
        })
        const columns = [
            { Header: getText('arabic'), accessor: 'Ar', isSorted: true },
            { Header: getText('english'), accessor: 'En' },
            { Header: getText('disabled'), accessor: 'Disable' },
        ];
        return { validationSchema, columns };
    }, [lng]);
    return (
        <DHead title={getText(settings.title)} theme={settings.theme}>
            <DefaultTable
                // url="/api/countries"
                {...settings}
                columns={columns}
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{ Id: 0, Type: Type, Ar: "", En: "", Disable: false }}
                editorProps={{
                    size: "md"
                }}
                log
            />
        </DHead>
    )
}
