import { Formik } from 'formik'
import React, { useState } from 'react'
import FormInput from '../../components/FormInput'
import { axiosClient, getText, pickText } from '../../Utlities/app-store'
import { DateToInputeDate, PrintElement } from '../../Utlities/functions'
import * as Yup from "yup"
import { ReportBody } from '../../components/Report'

export default function TravelReport() {
    const [rows, setRows] = useState(null)
    const validationSchema = Yup.object({
        date: Yup.date().required(getText('requiredf'))
    })
    const handlePrint = () => {
        PrintElement(document.querySelector('[data-report-container]'))
    }
    return (
        <>
            <Formik
                initialValues={{ date: DateToInputeDate(new Date()) }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    axiosClient.get('/api/requests/arrivalreport?date=' + values.date).then(({ data }) => {
                        setRows(data);
                    }).finally(setSubmitting(false))
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <div className="d-flex rtl">
                        <div className='flex-grow-1'>
                            <FormInput type='date' propName="date" title={getText('arrivalWorkerDate')} />
                        </div>
                        <div className="btn-group">
                            <button className='btn btn-primary' type="button" disabled={isSubmitting} onClick={handleSubmit} style={{ marginLeft: '6px' }}>{getText('search')}</button>
                            {rows !== null && rows.length > 0 && <button className='btn btn-dark' type="button" disabled={isSubmitting} onClick={handlePrint} style={{ marginLeft: '0px', paddingLeft: "0 !important" }} ><i className="fa-solid fa-print mx-2" ></i>{getText('print')}</button>}
                        </div>
                    </div>
                )}
            </Formik>
            {rows !== null && (
                rows.length > 0 ? <>
                    <ReportBody titleAr="تقرير وصل العاملات" titleEn="Workers arrival report">
                        <table className="table same-width font-weight-bold border small table-bordered table-sm">
                            <thead className="text-white bg-primary-soft table-dark" >
                                <tr><th>{getText('name')}</th><th>{getText('type0')}</th></tr>
                            </thead>
                            <tbody>
                                {rows.map(({ Name, En, Ar }, index) => <tr key={index}><td>{Name}</td><td>{pickText(Ar, En)}</td></tr>)}
                            </tbody>
                        </table>
                    </ReportBody>

                </> : <p className="text-center p-2 text-muted">{getText('nodatafound')}</p>
            )}
        </>
    )
}
