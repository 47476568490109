import React from 'react'
import Footer from './Footer';

export default function SinglePageLayout({ title, children }) {
    return (
        <main className="main-content mt-0 ps">
            <div className="page-header align-items-start min-vh-100" style={{ backgroundImage: `url('/content/bg.jpg')` }}>
                <span className="mask bg-gradient-dark opacity-6"></span>
                <div className="container my-auto">
                    <div className="row">
                        <div className="col-lg-4 col-md-8 col-12 mx-auto">
                            <div className="card z-index-0 fadeIn3 fadeInBottom">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div className="bg-gradient-primary shadow-primary border-radius-lg py-5 pe-1">
                                        <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">{title}</h4>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className='position-absolute' link={false} />
            </div>
            {/* <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
            <div className="ps__thumb-x" tabindex="0" style={{ left: 0, width: 0 }}></div>
        </div>
        <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
            <div className="ps__thumb-y" tabindex="0" style={{ top: 0, height: 0 }}></div>
        </div> */}
        </main>
    )
}
