import React, { useState, useMemo } from 'react'
import { getRapidTheme } from '../Utlities/functions';
import FilterItemsPage from './FilterItemsPage';
import { getText } from '../Utlities/app-store';


const fs = [
    { title: "countries", iconClass: "fa-solid fa-earth-africa" },
    { title: "jobs", iconClass: "fa-solid fa-screwdriver-wrench" },
    { title: "categories", iconClass: "fa-solid fa-lines-leaning" },
    { title: "experiances", iconClass: "fa-solid fa-kitchen-set" },
    { title: "religions", iconClass: "fa-solid fa-mosque" },
    { title: "statuses", iconClass: "fa-solid fa-signal" },
    { title: "followup", iconClass: "fa-solid fa-diagram-predecessor" },
    //Statuses
];

export default function FiltersPage() {
    const [current, setCurrent] = useState(null);
    return (
        <>
            <div className="row my-3">
                {fs.map((item, index) => <CardButton key={item.title} {...item} theme={getRapidTheme(index)} onClick={() => setCurrent(index)} className={(current === index ? "disabled" : "")} />)}
            </div>
            {current !== null && <FilterItemsPage Type={current} />}
        </>
    )
}

export const CardButton = ({ iconClass, theme = "dark", title, decription = "", className = "", ...etc }) => {
    return (
        <div className="col-lg-3 col-sm-4 mb-4">
            <div className={`card hover-effect scale ${className}`} {...etc}>
                <div className="card-header p-3 pt-2">
                    <div className={`icon icon-lg icon-shape bg-gradient-${theme} shadow-${theme} text-center border-radius-xl mt-n4 position-absolute`}>
                        <i className={iconClass}></i>
                    </div>
                    <div className="text-end pt-1">
                        <h4 className="mb-0">{getText(title)}</h4>
                    </div>
                </div>
                <hr className="dark horizontal my-0" />
                <div className="card-footer py-0 jrtl">
                    <span className="mb-0 text-xs ">
                        {decription ?? <>{getText('manage')} {getText(title)}</>}
                    </span>
                </div>
            </div>
        </div>
    )
}