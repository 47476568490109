export const ar = {
  type0: "الدولة",
  type1: "الوظيفة",
  type2: "النوع",
  type3: "الخبرة",
  type4: "الديانة",
  type5: "حالة الطلب",
  search: "البحث",
  pleasewait: "الرجاء الإنتظار",
  whatsapp: "وتسـاب",
  requestnow: "إطلب الأن",
  requesttitle: "تقديم طلب",
  name: "الإسم",
  identityid: "رقم الهوية",
  dob: "تاريخ الميلاد",
  phone: "رقم الهاتف",
  hasvisa: "لدي تأشيرة",
  borderno: "رقم الحدود",
  visanumber: "رقم الصادر",
  visadate: "تاريخ الصادر",
  workplace: "جهة العمل",
  workcity: "مدينة العمل",
  reltivename: "اسم احد اقارب من الدرجة الأولى",
  reltivephone: "تلفون القريب",
  reltiveworkplace: "جهة عمل الشخص القريب",
  address: "العنوان",
  floorcount: "عدد ادوار المنزل",
  roomscount: "عدد الغرف في المنزل",
  familycount: "عدد افراد الأسرة",
  notes: "ملاحظة",
  send: "إرسال",
  cancel: "إلغاء",
  requiredf: "هذا الحقل مطلوب",
  code: "كود التأكيد",
  identitymax: "لايمكن ان يكون رقم الهوية اكثر من 10 ارقام",
  invalidvalue: "القيمة غير مقبولة",
  invalidphone: "رقم الهاتف غير صحيح",
  sent: "تم الإرسال بنجاح",
  close: "إغلاق",
  refrencenumber: "رقم الطلب #",
  notification: "تنوية",
  login: "تسجيل الدخول",
  password: "رمز المرور",
  confirmpassword: "تأكيد رمز المرور",
  forgetpassword: "نسيت كلمة السر",
  resetpassword: "إعادة تعيين كلمة المرور",
  email: "البريد الإلكتروني",
  signin: "تسجيل الدخول",
  enter: "الدخول",
  enteryouremail: "الرجاء كتابة بريدك الإلكتروني",
  irememberedpassword: "لقد تذكرت رمز المرور",
  minpassword: "كلمة المرور يجب أن تكون أكثر أو تساوي 8 أحرف",
  confirmdoesnotmatch: "لا يتطابق مع حقل كلمة المرور.",
  lockedaccount: "الحساب مقفل، الرجاء الاتصال بإدارة الموقع للمراجعة",
  accountnotactive:
    "لم يتم تأكيد بريدك الالكتروني. الرجاء مراجعة بريدك الالكتروني لتفعيل حسابك",
  noaccountmatch: "لا يوجد حساب مطابق للمعلومات المدخلة",
  emailsenttoresetpass:
    "تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور الخاصة بك ، يرجى اتباع الخطوات المذكورة في البريد الإلكتروني.",
  selectnewpass: "الرجاء إختيار رمز مرور جديد",
  passwordupdated: "تم تغيير رمز المرور بنجاح",
  changepassword: "تغيير رمز المرور",
  resetcodesent:
    "تم إرسال بريد إلكتروني برمز إعادة التعيين إلى بريدك الإلكتروني ، يرجى التحقق من بريدك الإلكتروني وكتابة الرمز الذي تلقيته.",
  typecodehere: "اكتب الرمز هنا",
  codeexpired: "انتهت صلاحية الرمز",
  codeexpires: "تنتهي صلاحية الرمز خلال 5 دقائق",
  checkcode: "تحقق من صحة الكود",
  requestnewcode: "أطلب كود جديد",
  codenotmatch:
    "الرمز غير مطابق ، يرجى التحقق مما إذا كنت قد كتبت الرمز الصحيح أو طلبت رمزًا جديدًا.",
  dashboard: "الرئيسية",
  requests: "الطلبات",
  persons: "السير الذاتية",
  filters: "التصنيفات",
  users: "المستخدمين",
  new: "جديد",
  delete: "حذف",
  edit: "تعديل",
  pagelength: "حجم الجدول",
  // search: "بحث",
  role: "الصلاحية",
  lockedenabled: "الحساب مغلق",
  gotopage: "أذهب الى الصفحة",
  page: "صفحة",
  outof: "من أصل",
  showing: "مشاهدة",
  records: "سجل",
  manage: "إدارة",
  arabic: "اللغة العربية",
  english: "اللغة الأنجليزية",
  disabled: "معطل",
  save: "حفظ",
  ref: "سجل",
  available: "متوفر",
  resume: "السيرة الذاتية",
  notsaved: "لم يتم الحفظ",
  saved: "تم الحفظ بنجاح",
  unabletoloadpreq:
    "تعذر تحميل بعض المتطلبات ، يرجى تحديث الصفحة والمحاولة مرة أخرى.",
  confirmation: "تأكيــــد",
  aresure: "هل أنت متأكد؟",
  yes: "نعم",
  deletedsuccessfully: "تم الحذف بنجاح",
  unabletodelete:
    "حدث خطاء اثناء الحظر او انه لا يمكن حذف البيانات المرتبطة بسجلات اخرى",
  nodatafound: "لم يتم العثور على اي بيانات.",
  admin: "إدارة",
  editor: "تعديل",
  reader: "قراءة فقط",
  countries: "الدول",
  statuses: "حالات الطلبات",
  jobs: "الوظائف",
  categories: "الأنواع",
  experiances: "الخبرات",
  religions: "الأديان",
  followup: "حالات المتابعة",
  add: "إضافة",
  mustselectrole: "يجب إختيار صلاحية للمستخدم",
  requestdate: "تاريخ الطلب",
  requeststatus: "حالة الطلب",
  yus: "لماذا دار النخبة الافضل!",
  yusdesc:
    "نسعى ان نكون الافضل في تقديم خدمة الاستقدام من خلال استقطاب افضل الايدي العمالية الماهرة",
  beintouch: "لنبق على تواصل!",
  touchlocation: "اضغط هنا للوصول الى الموقع",
  touchemail: "الايميل",
  touchphone: "رقم الهاتف",
  logout: "تسجيل الخروج",
  employee: "العـامل",
  employeeinfo: "معلومات العامل",
  relativetype: "صلة القرابة",
  sendrequestnow: "تقدم بطلبك الان",
  hjri: "هجري",
  print: "طباعة",
  preview: "معاينة الطلب",
  setemployee: "تحديد | تغيير العامل",
  unabletosubmit:
    "يتعذر إرسال طلبك الآن، يرجى تحديث الصفحة والمحاولة مرة أخرى.",
  personnotavailable:
    "السيرة الذاتية المحددة لم تعد متوفرة، يرجى اختيار سيرة ذاتية أخرى والمحاولة مرة أخرى.",
  last10: "أخر 10 طلبات",
  clientName: "إسم العميل",
  empName: "إسم العامل",
  todaysrequests: "طلبات اليوم",
  weekrequests: "طلبات 7 إيام",
  twoweeksrequests: "طلبات اسبوعين",
  monthrequests: "طلبات شهر كامل",
  twomonthrequests: "طلبات شهرين",
  changelog: "سجل التغييرات",
  changeby: "قام بالتغيير",
  operation: "العملية",
  changedate: "تاريخ التغيير",
  opAdd: "إضافة",
  opEdit: "تعديل",
  column: "العمود",
  value: "القيمة",
  Ar: "عربي",
  En: "إنجليزي",
  Disable: "معطل",
  Name: "الإسم",
  Country: "الدولة",
  Religion: "الديانة",
  Job: "الوظيفة",
  Category: "التصنيف",
  Experiance: "الخبرة",
  Photo: "الصورة",
  Available: "متوفر",
  RequestDate: "تاريخ الطلب",
  Dob: "تاريخ الميلاد",
  IdentityId: "الرقم الوطني",
  Phone: "رقم الهاتف",
  HasVisa: "لدية فيزا",
  VisaNumber: "رقم الفيزا",
  WorkCity: "مدينة العمل",
  BorderNo: "رقم الحدود",
  WorkPlace: "جهة العمل",
  RelativeName: "اسم القريب",
  RelativePhone: "هاتف القريب",
  RelativeType: "نوع القريب",
  RelativeWorkPlace: "جهة عمل القريب",
  Address: "العنوان",
  Note: "ملاحظات",
  FloorCount: "عدد الطوابق",
  RoomsCount: "عدد الغرف",
  FamilyCount: "عدد افراد الاسرة",
  Person: "العامل",
  Status: "حالة الطلب",
  thanyesterday: "مقارنة بالأمس",
  thanlastweek: "مقارنة بالاسبوع السابق",
  thanlastmonth: "مقارنة بالشهر الماضي",
  update: "تحديث",
  offices: "مكاتب العمالة",
  officeName: "إسم المكتب",
  followupSection: "قسم المتابعة",
  //"FilterId", "BoloEntered", "BoloRecived", "PaidMusand", "LinkMusand", "SignMusand", "Contract", "ContractNo", "Medical", "Biometric", "OWWA", "OEC", "Egency", "EnteredEmbessy", "VisaRecived", "Travel", "FollowUpNote"
  followStatus: "حالة المتابعة",
  boloEntered: "تاريخ إدخال البولو",
  boloRecived: "تاريخ استلام البولو",
  paidMusand: "تاريخ سداد مساند",
  contractNo: "رقم العقد",
  contractDate: "تاريخ العقد",
  linkMusand: "تاريخ الربط على مساند",
  signMusand: "تاريخ توقيع العامل على مساند",
  agency: "تاريخ الوكالة",
  embessyEntered: "تاريخ دخول الاوراق السفارة",
  visaRecived: "تاريخ استلام التأشيرة",
  travelDate: "تاريخ السفر",
  followNote: "ملاحظات المتابعة",
  orderby: "ترتيب حسب",
  reports: "التقارير",
  travelReport: " وصول العاملات",
  travelReportDescription: "قائمة العاملات على أساس تاريخ الوصول",
  arrivalWorkerDate: "تاريخ وصول العاملات",
  invalidEmail: "البريد الإلكتروني غير صحيح",
  status: "الحالة",
  inEmbessy: "بالسفارة",
  gotVisa: "التفييز",
  // travelDate: "ميعاد السفر",
  auth: "التفويض",
  archived: "ارشفة الطلب",
  showArchived: "إظهار الارشيف",
};
export const en = {
  showArchived: "Show Archived",
  archived: "Archive Request",
  auth: "Auth.",
  // travelDate: "Travel Date",
  gotVisa: "Got Visa",
  inEmbessy: "At Embassy",
  status: "Status",
  invalidEmail: "Invalid email address",
  arrivalWorkerDate: "Workers arrival date",
  travelReportDescription: "List of employees based on arrival date.",
  travelReport: "Workers arrival ",
  reports: "Reports",
  orderby: "Order by ",
  linkMusand: "Linked to Musaned Date",
  signMusand: "Sign Musaned Date",
  agency: "Agency Date",
  embessyEntered: "Paper Entered Embessay Date",
  visaRecived: "Visa Picked Date",
  travelDate: "Travel Date",
  followNote: "Follow-Up Date",
  contractNo: "Contract No",
  contractDate: "Contract Date",
  paidMusand: "Pay Musaned Date",
  boloRecived: "Bolo Recived Date",
  boloEntered: "Bolo Entered Date",
  followStatus: "Follow-Up Status",
  followupSection: "Follow-Up Section",
  offices: "Labor offices",
  officeName: "Office Name",
  update: "Update",
  thanyesterday: " than yesterday.",
  thanlastweek: "than last week.",
  thanlastmonth: "than last month.",
  RequestDate: "Request Date",
  Dob: "Date of Bith",
  IdentityId: "Identity Id",
  Ar: "Arabic",
  En: "English",
  column: "Column",
  value: "Value",
  opAdd: "New",
  opEdit: "Edit",
  changeby: "Changed By",
  operation: "Operation",
  changedate: "Change Date",
  changelog: "Change Logs",
  monthrequests: "A Month Requests",
  twomonthrequests: "Two Months Requests",
  twoweeksrequests: "2 Weeks Requests",
  weekrequests: "7 Days Requests",
  todaysrequests: "Today's Requests",
  empName: "Employee Name",
  clientName: "Client Name",
  last10: "Last 10 Requets",
  unabletosubmit:
    "Unable to submit your request right now, please refresh the page and try again.",
  personnotavailable:
    "The selected CV is not available anymore, please pick another CV and try again.",
  setemployee: "Set | Change Employee",
  preview: "Preview Application",
  print: "Print",
  hjri: "Hijri",
  relativetype: "Relative Type",
  borderno: "Border Number",
  employee: "The Employee",
  employeeinfo: "Employee Information",
  logout: "Logout",
  beintouch: "Be in TOUCH!",
  touchlocation: "Click here to navigate to our location",
  touchemail: "Email",
  touchphone: "Phone Number",
  yus: "Why US!",
  yusdesc:
    "We strive to be the best in providing recruitment service by attracting the best skilled labor hands",
  statuses: "Request Statuses",
  saved: "Saved Successfully",
  requeststatus: "Request Status",
  requestdate: "Request Date",
  mustselectrole: "Role must be selected",
  add: "Add",
  countries: "Countries",
  jobs: "Jobs",
  categories: "Types",
  experiances: "Experiances",
  religions: "Religions",
  followup: "Follow-Up Statuses",
  admin: "Admin",
  editor: "Editor",
  reader: "Reader",
  nodatafound: "No data found.",
  unabletodelete:
    "An error occurred while blocking or the data associated with other records cannot be deleted.",
  deletedsuccessfully: "Deleted Successfully",
  yes: "Yes",
  aresure: "Are you sure?",
  confirmation: "Confirmation:",
  unabletoloadpreq:
    "Some requirements could not be loaded, please refresh the page and try again.",
  notsaved: "Not saved!",
  dashboard: "Dashboard",
  requests: "Requests",
  persons: "CV",
  filters: "Filters",
  users: "Users",
  new: "New",
  delete: "Delete",
  edit: "Edit",
  pagelength: "Page Length",
  search: "Search",
  role: "Role",
  lockedenabled: "Locked",
  gotopage: "Go to page",
  page: "Page",
  outof: "out of",
  showing: "Showing",
  records: "records",
  manage: "Manage",
  arabic: "Arabic Language",
  english: "English Language",
  disabled: "Disabled",
  save: "Save",
  ref: "Ref",
  available: "Available",
  resume: "Resume",
  codenotmatch:
    "The code does not match, please check if you typed the right code or request a new code.",
  requestnewcode: "Request new code",
  checkcode: "Check Code",
  codeexpired: "The code is expire",
  codeexpires: "The code will expire within 5 Minutes",
  typecodehere: "Type code here",
  resetcodesent:
    "An email with a reset code has been sent to your email, please check your email and type the code you received.",
  changepassword: "Change Password",
  passwordupdated: "Password updated successfully!",
  selectnewpass: "Please select new password.",
  emailsenttoresetpass:
    "An email has been sent to reset your password, please follow the steps mentioned in the email.",
  noaccountmatch: "There is no account that matches the information entered.",
  accountnotactive:
    "Your email has not been confirmed. Please check your email to activate your account.",
  lockedaccount:
    "The account is locked, please contact the site administration for review.",
  confirmdoesnotmatch: "Does not match password field.",
  minpassword: "Password must be more or equal to 8 characters",
  irememberedpassword: "I remembered my passowrd",
  enteryouremail: "Please enter your email address",
  enter: "Enter",
  signin: "Sign In",
  email: "Email",
  login: "Login",
  password: "Password",
  confirmpassword: "Confirm Password",
  forgetpassword: "Forget Password",
  resetpassword: "Reset Password",
  notification: "Notification",
  refrencenumber: "Reference No. #",
  close: "Close",
  sent: "Sent Successfully!",
  invalidphone: "Invalid Phone",
  invalidvalue: "Ivalid Value",
  identitymax: "Identity length can not be more than 10 digits",
  code: "Verification Code",
  requiredf: "This field can not be empty!",
  cancel: "Cancel",
  send: "Send",
  notes: "Notes",
  familycount: "Number of family members",
  roomscount: "Number of rooms",
  floorcount: "Number of floors",
  address: "Address",
  reltiveworkplace: "The employer of the person close to you",
  reltivephone: "Relative Phone",
  reltivename: "Name of a first-degree relative",
  workcity: "Work City",
  workplace: "Work Place",
  visadate: "Visa Date",
  visanumber: "Visa Number",
  hasvisa: "Has Visa Already",
  phone: "Phone",
  dob: "Date of birth",
  identityid: "Identity Id",
  name: "Name",
  requesttitle: "Request Application",
  type0: "Country",
  type1: "Job",
  type2: "Category",
  type3: "Experiance",
  type4: "Religion",
  type5: "Request Status",
  // search: "Search",
  pleasewait: "Please wait",
  whatsapp: "Whatsapp",
  requestnow: "Request Now",
  sendrequestnow: "Send your request now",
};
