import React, { useState, useEffect, useMemo } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import FormInput from '../components/FormInput';
import LoadingSpinner from '../components/LoadingSpinner';
import FormCheckBox from '../components/FormCheckBox';
import FormTextArea from '../components/FormTextArea';
import { CheckCell, ClearBase64Url, ResizeImages, consts } from '../Utlities/functions';
import $ from "jquery"
import FormSelect from './../components/FormSelect';
import { getText, languageState, pickText } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';
import OrderByOffice from '../components/OrderByOffice';

const FormContent = ({ values, setFieldValue, ...etc }) => {
    const handleImageChange = () => {
        // Create an input element dynamically
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = ".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*";
        // Trigger file selection when the input element is clicked
        input.click();
        // Listen for file selection
        input.addEventListener('change', function (e) {
            var file = e.target.files[0];
            // uploadWordDocument(file);
            ResizeImages(file, dataUrl => {
                setFieldValue("File", ClearBase64Url(dataUrl));
                $('img[data-service-img]').attr('src', dataUrl);
            })
        });
    }
    // function uploadWordDocument(file) {
    //     var formData = new FormData();
    //     formData.append('file', file);
    //     $.ajax({
    //         url: '/api/persons/w2img',
    //         type: 'POST',
    //         data: formData,
    //         processData: false,
    //         contentType: false,
    //         success: function (response) {
    //             setFieldValue("File", response);
    //             setFieldValue("Photo", "");
    //             // Handle the response from the API
    //         },
    //         error: function (xhr, status, error) {
    //             console.error('Error uploading file:', error);
    //             // Handle the error
    //         }
    //     });
    // }
    const imgSrc = useMemo(() => {
        if (values.File) return `data:image/png;base64, ${values.File}`;
        if (values.Photo) return `/content/${values.Photo}`
        return null;
    }, [values.Photo, values.File])
    return (
        <>
            <div className="col-md-6">
                <div>
                    <FormSelect propName="OfficeId" title={getText('officeName')}>
                        <option></option>
                        {consts.offices.map(item => <option value={item.Id} key={item.Id}>{pickText(item.NameArabic, item.NameEnglish)}</option>)}
                    </FormSelect>
                </div>
                <div><FormInput required propName="Name" title={getText('name')} /></div>
                <div>
                    <FormSelect required propName="Country" title={getText('type0')}>
                        <option></option>
                        {consts.filters.filter(c => c.Type === 0).map(item => <option value={item.Id} key={item.Id}>{item.Ar}</option>)}
                    </FormSelect>
                </div>
                <div>
                    <FormSelect required propName="Job" title={getText('type1')}>
                        <option></option>
                        {consts.filters.filter(c => c.Type === 1).map(item => <option value={item.Id} key={item.Id}>{item.Ar}</option>)}
                    </FormSelect>
                </div>
                <div>
                    <FormSelect required propName="Category" title={getText('type2')}>
                        <option></option>
                        {consts.filters.filter(c => c.Type === 2).map(item => <option value={item.Id} key={item.Id}>{item.Ar}</option>)}
                    </FormSelect>
                </div>
                <div>
                    <FormSelect required propName="Experiance" title={getText('type3')}>
                        <option></option>
                        {consts.filters.filter(c => c.Type === 3).map(item => <option value={item.Id} key={item.Id}>{item.Ar}</option>)}
                    </FormSelect>
                </div>
                <div>
                    <FormSelect required propName="Religion" title={getText('type4')}>
                        <option></option>
                        {consts.filters.filter(c => c.Type === 4).map(item => <option value={item.Id} key={item.Id}>{item.Ar}</option>)}
                    </FormSelect>
                </div>
                <div>
                    <FormTextArea propName="Note" title={getText('notes')} />
                </div>
                <div>
                    <FormCheckBox propName="Available" title={getText('available')} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="list-group text-sm">
                    <div className="list-group-item list-group-item-light d-flex align-items-center justify-content-between">{getText('resume')}<span className="btn-group btn-group-sm"><span className='btn btn-info m-0' onClick={handleImageChange}>{getText('add')}</span>{values.Photo || values.File ? <span className='btn btn-danger m-0' onClick={() => {
                        setFieldValue("File", "");
                        setFieldValue("Photo", "");
                    }}>{getText('delete')}</span> : null}</span></div>
                    <div className="list-group-item h-100">
                        {imgSrc &&
                            <img src={imgSrc} className='w-100' />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
const childFunction = {}
export default function PersonsPage() {
    const [loaded, setLoaded] = useState(false);
    const lng = useRecoilValue(languageState)
    useEffect(() => {
        if (!loaded) {
            if (consts.filters) {
                setLoaded(true);
            } else {
                $.ajax("/api/filters/getdata").then(data => {
                    consts.filters = data;
                    setLoaded(true);
                })
            }
        }
    }, [])

    function Cell({ value, row }) {
        if (!consts.filters) return "";
        return consts.filters.find(c => c.Id == value).Ar
    }

    const { columns, validationSchema, title } = useMemo(() => {
        const validationSchema = Yup.object().shape({
            Name: Yup.string().required().label("Name").max(100, "Maximum string lenth is 100"),
            Country: Yup.string().required(),
            Religion: Yup.string().required(),
            Job: Yup.string().required(),
            Category: Yup.string().required(),
            Experiance: Yup.string().required(),
        })
        const columns = [
            { Header: getText('ref'), accessor: 'Ref', disableSortBy: true },
            { Header: getText('name'), accessor: 'Name', isSorted: true },
            { Header: getText('type0'), accessor: 'Country', Cell },
            { Header: getText('type1'), accessor: 'Job', Cell },
            { Header: getText('type2'), accessor: 'Category', Cell },
            { Header: getText('type3'), accessor: 'Experiance', Cell },
            { Header: getText('type4'), accessor: 'Religion', Cell },
            { Header: getText('available'), accessor: 'Available', Cell: CheckCell },
        ];
        const title = getText("persons");
        return { columns, validationSchema, title }
    }, [lng])
    if (!loaded) return <LoadingSpinner />

    return (
        <DHead title={title} theme="success">
            <DefaultTable
                url="/api/persons"
                columns={columns}
                theme="success"
                title={title}
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={{ Id: 0, Name: "", Country: "", Religion: "", Job: "", Category: "", Experiance: "", Available: true, }}
                editorProps={{
                    size: "xl"
                }}
                prerequisite={() => {
                    return consts.offices && consts.offices.length > 0;
                }}
                filterComponenets={<OrderByOffice onChange={e => {
                    childFunction.refresh({ officeId: e.currentTarget.value })
                }} />}
                childFunction={childFunction}
                log
            />
        </DHead>
    )
}
