import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table';
// import ReactDOM from 'react-dom';
// import $ from 'jquery'
import { Formik } from 'formik';
import BModel from "./BModel"
import { DateTimeArabic, ShallowEqual, consts, updateDateProperties } from '../Utlities/functions';
import { useRecoilValue } from 'recoil';
import { axiosClient, getText, languageState, userState } from '../Utlities/app-store';
import { Hint, Wait, Ask, Alert } from 'fnon';
// import JustSelect from './JustSelect';
import JustInput from './JustInput';
import $ from 'jquery'

// Fnon.Dialogue.Init({ width: 'lg', zIndex: 1999, animation: 'slide-bottom', closeButton: true, showMaxMin: true, defaultBefore, autoFocus: true, autoFocusTag: 'input:not([type="hidden"]):not([disabled]):not([readonly]), select:not([disabled])' });

const Editor = ({ data, isNew, FormContent, schema, liveSchema, url, callback, setShow, theme, editorProps = {} }) => {
    //      contentType: false, // Not to set any content header  
    //      processData: false, // Not to process data 
    return <Formik
        initialValues={data}
        validationSchema={(() => {
            if (typeof schema === 'function')
                return schema(data, isNew);
            else
                return schema
        })()}
        onSubmit={(values, { setFieldError, setSubmitting }) => {
            if (consts.isEditor()) {
                if (!ShallowEqual(values, data)) {
                    // let fd = {};
                    // fd = { data: values };
                    axiosClient.postForm(url + '/Edit', values).then(() => callback(true)).catch(({ status, responseJSON }) => {
                        if (status === 409 && responseJSON && Array.isArray(responseJSON)) {// Confilict
                            responseJSON.forEach(({ field, errorMsg }) => setFieldError(field, errorMsg));
                        } else {
                            Hint.Danger(getText('notsaved'));
                        }
                        setSubmitting(false);
                    })
                    // $.ajax({ url: url + '/Edit', method: 'Post', ...fd }).then(() => {
                    //     callback(true);
                    // }).catch(({ status, responseJSON }) => {
                    //     if (status === 409 && responseJSON && Array.isArray(responseJSON)) {// Confilict
                    //         responseJSON.forEach(({ field, errorMsg }) => setFieldError(field, errorMsg));
                    //     } else {
                    //         Hint.Danger(getText('notsaved'));
                    //     }
                    //     setSubmitting(false);
                    // });
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        }}
    >
        {({ handleSubmit, ...etc }) => (
            <BModel title={getText(isNew ? "new" : "edit")} okText={getText('save')} theme={theme} handleSubmit={handleSubmit} cancelText={getText('cancel')} show={true} size="xl" setShow={() => setShow()} {...editorProps}>
                <>
                    <form onSubmit={handleSubmit} className="row rtl">
                        <FormContent {...etc} handleSubmit={handleSubmit} />
                    </form>
                </>
            </BModel>
        )}
    </Formik>
}

const GetPrerequisite = (checkFun, url, values) => {
    return new Promise((resolve, reject) => {
        if (!checkFun || checkFun(values)) return resolve();
        // Wait.Bricks('Loading Prerequisite..');
        axiosClient.get(url + '/prerequisite').then(({ data }) => {
            if (data) {
                Object.keys(data).forEach(key => consts[key] = data[key]);
            }
            // console.log(d);
            return resolve();
        }).catch(() => {
            return reject()
        })
        // $.ajax(url + '/prerequisite').then(d => {
        //     Object.keys(d).forEach(key => consts[key] = d[key]);
        //     // console.log(d);
        //     return resolve();
        // }).catch(() => {
        //     return reject()
        // })
        // .always(() => Wait.Remove());
    });
}
const listChanges = (changes) => {
    var obj = JSON.parse(changes);
    return Object.keys(obj).reduce((ac, i) => ac += `<tr><td colspan="2">${getText(i)}</td><td colspan="4">${obj[i] ?? ''}</td></tr>`, '');
}
const Table = ({ title, columns, childFunction, hideAddButton = false, hideDeleteButton = false, hideEditButton = false, dontEditColumns = false, data, fetchData, loading, tableInfo, schema, FormContent, url, defaultValues, prerequisite, deleteUrl, deletePropToSend = "Id", log, customEditHandler, refresh, extraButtons, filterComponenets, ...etc }) => {
    const lng = useRecoilValue(languageState)
    let sv = "";
    const [editRecord, setEditRecord] = useState(null);
    const handleEdit = (values, isNew = false) => {
        GetPrerequisite(prerequisite, url, values).then(() => {
            //updateDateProperties
            const nval = JSON.parse(JSON.stringify(values)); updateDateProperties(nval);
            setEditRecord(nval);
        }).catch((err) => {
            console.log(err);
            Hint.Danger(getText('unabletoloadpreq'))
        })
    }
    const handleDelete = (id) => {
        Ask.Danger({
            title: getText('confirmation'),
            message: getText('aresure'),
            btnOkText: getText('yes'),
            width: "sm",
            btnCancelText: getText('cancel'),
            callback: y => {
                if (y) {
                    axiosClient.get((deleteUrl ?? url + '/delete') + '?id=' + id).then((resp) => {
                        if (resp.data) {
                            Hint.Success(getText('deletedsuccessfully'))
                            callFeatch();
                        } else {
                            Hint.Danger(getText('unabletodelete'))
                        }
                    }).catch(() => Hint.Danger(getText('unabletodelete')))
                    // $.ajax((deleteUrl ?? url + '/delete') + '?id=' + id,).then((resp) => {
                    //     if (resp) {
                    //         Hint.Success(getText('deletedsuccessfully'))
                    //         callFeatch();
                    //     } else {
                    //         Hint.Danger(getText('unabletodelete'))
                    //     }
                    // }).catch(() => Hint.Danger(getText('unabletodelete')))
                }
            }
        })
    }
    const handleLog = (id) => {
        Wait.Bricks(getText('pleasewait'));
        axiosClient.get((url + '/log') + '?id=' + id).then(({ data }) => {
            Alert.Info({
                title: getText('changelog') + ' : #' + id,
                width: 'xl',
                message: data.reduce((ac, i) => ac += `<div class="table-responsive small table-active p-0 mb-1"><table class="table table-sm small same-width table-bordered table-hover table-striped align-items-center mb-0"><thead class="hover-effect bg-info text-white "><tr><th colspan="2">${getText('changeby')} : ${i.ChangedBy}</th><th colspan="2">${getText('changedate')} : ${DateTimeArabic(i.Date)}</th><th colspan="2">${getText('operation')} : ${getText('op' + i.Operation)}</th><tr></thead><thead style="display:none"><tr><th colspan="2">${getText('column')}</th><th colspan="4">${getText('value')}</th></tr></thead><tbody style="display:none">${listChanges(i.Changes
                )}</tbody></table></div>`, ''),
                btnOkText: getText('close'),
                afterShow: h => {
                    $(h).find('thead.hover-effect').click(e => {
                        $(e.currentTarget).nextAll().fadeToggle('fast');
                    })
                }
            })
        }).catch(() => Hint.Danger(getText('nodatafound'))).finally(() => {
            Wait.Remove();
        })
    }
    const modifiedColumns = useMemo(() => {
        if (dontEditColumns) return columns;
        //const _cols = [...columns];

        return [...columns, {
            Header: '', disableSortBy: true, accessor: 'Id', Cell: ({ value, row }) => {
                return <>
                    <div className='d-flex flex-row-reverse'>
                        <div className="btn-group shadow btn-group-sm">{!hideDeleteButton && consts.isEditor() && <button type="button" className="btn btn-danger m-0 py-1" onClick={() => {
                            handleDelete(row.original[deletePropToSend]);
                        }}><i className="fa-solid fa-eraser fs-6 mr-6"></i>{getText('delete')}</button>}
                            {!hideEditButton && <button type="button" className="btn m-0 btn-info py-1" onClick={() => {
                                handleEdit({ ...row.original })
                            }}><i className="fa-regular fa-pen-to-square mr-6 fs-6"></i>{getText('edit')}</button>}
                            {log && consts.isAdmin() && <button type="button" className="btn m-0 btn-primary py-1" onClick={() => {
                                handleLog(row.original[deletePropToSend]);
                            }}><i className="fa-solid fa-info fs-6 mr-6"></i>{getText('changelog')}</button>}
                        </div>
                    </div>
                </>
            }
        }];
    }, [lng]);
    // const serchInput = useRef();
    let sortField = "";
    let isDesc = false;
    const tableInstance = useTable(
        {
            columns: modifiedColumns,
            data,
            initialState: { pageIndex: 0, sortBy: [{ id: 'Id', desc: true }] }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            // manualPagination: true,
            autoResetPage: false,
            // manualFilters:true,
            manualSortBy: true,
            // pageCount: tableInfo.pageCount,
            ...tableInfo
        },
        useSortBy,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // paginations
        // page,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = tableInstance
    const callFeatch = (extraAjx = {}) => {
        // console.log(serchInput.current)
        const searchValue = sv ?? " ";

        fetchData({ pageIndex, pageSize, headerGroups, tableInstance, searchValue, sortField, isDesc, extraAjx });
    };
    if (childFunction) {
        childFunction["refresh"] = callFeatch;
    }
    useEffect(() => {
        callFeatch();
        // console.log(pageOptions);
        // fetchData({ pageIndex, pageSize, headerGroups, tableInstance })
    }, [fetchData, pageIndex, pageSize])
    return (
        <>
            <>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center jrtl">
                        <div>
                            {!hideAddButton && <span className={`btn btn-${etc.theme ?? "primary"} btn-sm m-0`} onClick={() => {
                                if (customEditHandler) {
                                    customEditHandler();
                                } else {
                                    handleEdit({ ...defaultValues }, true)
                                }
                            }}>{getText('new')}</span>}

                            <span className='mx-2 small'>{getText('pagelength')}</span>
                            <select
                                className="form-select border form-select-sm form-control form-control-sm d-inline-block"
                                value={pageSize}
                                disabled={loading}
                                style={{ width: '100px' }}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50, 80, 100].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                            {filterComponenets}
                        </div>
                        <div>
                            <JustInput title={getText('search')} disabled={loading} onKeyDown={e => {
                                if (e.key === 'Enter' && e.currentTarget.value && e.currentTarget.value.trim().length > 0) {
                                    sv = e.currentTarget.value.trim();
                                    callFeatch();
                                } else if (e.key === 'Escape' && e.target.value) {
                                    sv = "";
                                    e.target.value = "";
                                    callFeatch();
                                }
                            }} />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive p-0">
                        <table className="table align-items-center mb-0" {...getTableProps()}>
                            {rows.length > 0 ?
                                <>
                                    <thead className={`bg-gradient-${etc.theme} shadow-${etc.theme}`}>
                                        {
                                            headerGroups.map(hg => (
                                                <tr className="text" {...hg.getHeaderGroupProps()}>
                                                    {hg.headers.map(column => {
                                                        return <th className='text-uppercase text-white text-xs font-weight-bolder' {...column.getHeaderProps(column.getSortByToggleProps())} onClick={() => {
                                                            // column.isSorted=true;
                                                            sortField = column.id;
                                                            isDesc = column.isSortedDesc;
                                                            column.toggleSortBy(!column.isSortedDesc);
                                                            callFeatch();

                                                        }}>
                                                            {/* return <th {...column.getHeaderProps()}> */}
                                                            {column.render('Header')}
                                                            {/* Add a sort direction indicator */}
                                                            <span className="m-2">{column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <i className="fa-solid fa-sort-down"></i>
                                                                    : <i className="fa-solid fa-sort-up"></i>
                                                                : !column.disableSortBy && null}</span>
                                                        </th>
                                                    })}
                                                </tr>
                                            ))
                                        }
                                    </thead>

                                    <tfoot>
                                        <tr className='text-xs'>
                                            {loading ? (
                                                // Use our custom loading state to show a loading indicator
                                                <td colSpan="10000">Loading Data...</td>
                                            ) : (
                                                <td colSpan="10000">
                                                    {getText('showing')} {tableInfo.show} {getText('outof')} {tableInfo.total} {getText('records')}
                                                </td>
                                            )}
                                        </tr>
                                        <tr><td colSpan="10000"><div className='d-flex flex-column justify-content-center align-items-center'>
                                            <nav>
                                                <ul className="pagination justify-content-center">
                                                    <li className={"page-item " + (!canPreviousPage ? 'disabled' : 'cursor-pointer')} onClick={() => gotoPage(0)}>
                                                        <span className="page-link">
                                                            <i className="fa-solid fa-angles-left"></i>
                                                        </span>
                                                    </li>
                                                    <li className={"page-item " + (!canPreviousPage ? 'disabled' : 'cursor-pointer')} onClick={() => previousPage()}>
                                                        <span className="page-link">
                                                            <i className="fas fa-chevron-left"></i>
                                                        </span>
                                                    </li>
                                                    <li className="text-xs mx-3 align-self-center text-secondary">
                                                        <span>
                                                            {getText('page')}
                                                            {' '}
                                                            <strong>
                                                                {pageIndex + 1} {getText('outof')} {Math.ceil(tableInfo.total / pageSize)}
                                                            </strong>{' '}
                                                        </span>
                                                    </li>
                                                    <li className={"page-item " + (!canNextPage ? 'disabled' : 'cursor-pointer')} onClick={() => nextPage()}>
                                                        <span className="page-link">
                                                            <i className="fas fa-chevron-right"></i>
                                                        </span>
                                                    </li>
                                                    <li className={"page-item " + (!canNextPage ? 'disabled' : 'cursor-pointer')} onClick={() => gotoPage(pageCount - 1)}>
                                                        <span className="page-link">
                                                            <i className="fa-solid fa-angles-right"></i>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <span className="mx-2">
                                                <span className='text-secondary text-xs'>{getText('gotopage')}</span>                                        <input
                                                    className="form-control border rounded form-control-sm d-inline-block no-spinner mx-2"
                                                    disabled={loading}
                                                    type="number"
                                                    defaultValue={pageIndex + 1}
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter' && e.target.value) {
                                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                            gotoPage(page)
                                                        } else if (e.key === 'Escape' && e.target.value) {
                                                            gotoPage(1)
                                                        }
                                                    }}
                                                    style={{ width: '100px' }}
                                                />
                                            </span>
                                        </div></td></tr>
                                    </tfoot></>
                                : null}
                            <tbody {...getTableBodyProps()}>
                                {rows.length > 0 ? rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()} onClick={e => {
                                            if (etc.onRowClicked) {
                                                etc.onRowClicked(e, row);
                                            }
                                        }}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }) : <tr><td colSpan="1000" className="text-center text-muted text-xs">{getText('nodatafound')}</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>

            </>
            {editRecord !== null && <Editor isNew={editRecord.Id === '' || editRecord.Id === 0 || editRecord.Id === null} setShow={() => setEditRecord(null)} data={editRecord} FormContent={FormContent} schema={schema} url={url} callback={(haschanged) => {
                if (haschanged) {
                    Hint.Success(getText("saved"))
                    callFeatch();
                }
                setEditRecord(null);
            }} {...etc} />}
        </>
    )
}
export default function DefaultTable({ url, children, childFunction, tabInfo = {}, ...etc }) {
    const user = useRecoilValue(userState);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [tableInfo, setTableInfo] = useState({
        total: 0,
        pageCount: 0,
        ...tabInfo
    })
    const fetchIdRef = useRef(0)
    const fetchData = useCallback(({ pageIndex, pageSize, searchValue, sortField, isDesc, extraAjx = {} }) => {
        const fetchId = ++fetchIdRef.current
        if (fetchId === fetchIdRef.current) {
            setLoading(true)
            const ajaxTable = childFunction && childFunction.ajaxTable ? childFunction.ajaxTable : {};
            axiosClient.get(url + '/get', {
                params: { sortField, isDesc, pageSize, pageIndex, searchValue, ...ajaxTable, ...extraAjx }
            }).then((resp) => {
                if (resp === undefined) {
                    fetchData({ pageIndex, pageSize, searchValue, sortField, isDesc, ...extraAjx });
                    return;
                }
                const { total, data, showing } = resp.data;
                setData(data);
                setTableInfo({
                    total,
                    pageCount: Math.ceil(total / pageSize),
                    show: showing
                });
                if (childFunction && childFunction.tableChanged) {
                    childFunction.tableChanged()
                }
            }).finally(() => {
                setLoading(false);
            })
            // $.ajax({ url: url + '/get', data: { sortField, isDesc, pageSize, pageIndex, searchValue } }).then((resp) => {
            //     const { total, data, showing } = resp;
            //     setData(data);
            //     setTableInfo({
            //         total,
            //         pageCount: Math.ceil(total / pageSize),
            //         show: showing
            //     });
            //     if (childFunction && childFunction.tableChanged) {
            //         childFunction.tableChanged()
            //     }
            // }).catch(console.log).always(() => {
            //     setLoading(false);
            // });
        }
    }, [url])
    if (childFunction) {
        childFunction['setData'] = setData;
    }
    if (!user) return null;
    return (
        <>
            <Table
                url={url}
                {...etc}
                fetchData={fetchData}
                data={data}
                loading={loading}
                tableInfo={tableInfo}
                childFunction={childFunction}
            />
            {children}
        </>
    )
}