import { Formik } from 'formik'
import React, { useMemo, useEffect } from 'react'
import * as Yup from "yup"
import FormInput from '../components/FormInput'
import { Link, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { getText, userState } from './../Utlities/app-store';
import $ from "jquery"
import { Hint, Ask } from "fnon"
import SinglePageLayout from '../components/SinglePageLayout'
import { consts } from './../Utlities/functions';



export default function LoginPage() {
    const setUser = useSetRecoilState(userState);
    const nav = useNavigate();
    const validationSchema = useMemo(() => Yup.object({
        username: Yup.string().required(getText('requiredf')).email(getText('invalidEmail')),
        password: Yup.string().min(8, getText('minpassword')).required(getText('requiredf'))
    }), [])
    useEffect(() => {
        document.querySelector('input[type="email"]')?.focus()
    }, [])
    //username: "adel@email.com", password: "12345678"
    return (
        <SinglePageLayout title={getText("signin")}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{ username: "", password: "" }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    $.ajax({
                        url: "/api/login/signin",
                        data: values,
                        headers: { 'x-gs-web': 'AdelCo' }
                    }).then(data => {
                        if (!data.EmailConfirmed || data.LockoutEnabled) {
                            Hint.Danger(getText(data.LockoutEnabled ? "lockedaccount" : "accountnotactive"), { position: "center-top", animation: "slide-top" })
                            resetForm();
                            return;
                        }
                        consts.user = data;
                        setUser(data);
                        nav("/")

                    }).catch(error => {
                        Hint.Danger(getText('noaccountmatch'), { position: "center-top", animation: "slide-top" })
                    }).always(() => {
                        setSubmitting(false);
                    })
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form role="form" className="py-3 row rtl" noValidate onSubmit={handleSubmit}>
                        <div>
                            <FormInput propName="username" title={getText("email")} type="email" />
                        </div>
                        <div>
                            <FormInput autoComplete='password' propName="password" title={getText("password")} type="password" />
                        </div>
                        <div className="text-center">
                            <button type="button" disabled={isSubmitting} onClick={handleSubmit} className="btn bg-gradient-primary w-100 my-4 mb-2">{getText("enter")}</button>
                        </div>
                    </form>
                )}
            </Formik>
            <p className="mt-4 text-sm text-center">
                {getText("forgetpassword")}!
                <Link to="/resetpassword" className="text-primary text-gradient font-weight-bold mx-1">{getText("resetpassword")}</Link>
            </p>
        </SinglePageLayout>

    )
}
