import { useFormikContext } from 'formik'
import React, { useMemo, useState } from 'react'

export default function FormSelect({ propName, required, inputProps, containerClassName, title, type = "text", children, ...etc }) {
    const { isSubmitting, values, handleChange, errors } = useFormikContext();
    const invalid = useMemo(() => errors && errors[propName], [errors])
    const navigator = useMemo(() => propName ?? title, [])
    const [isFoucsed, setIsFoucsed] = useState(false);
    return (
        <div className={`input-group input-group-outline mb-3${(isFoucsed || values[navigator] ? ' is-focused' : '')} ${invalid ? 'is-invalid' : values[navigator] ? 'is-valid' : ''}`}>
            <label className="form-label">{title} {required && <span className='mx-1 text-danger'>*</span>}</label>
            <select
                value={values[navigator] ?? ""}
                disabled={isSubmitting}
                onFocus={() => setIsFoucsed(true)}
                onBlur={() => setIsFoucsed(false)}
                onChange={handleChange(navigator)}
                className={`form-control${invalid ? " invalid" : ""}`} {...inputProps} {...etc}>
                {children}
            </select>
            {invalid && <div className="invalid-feedback">
                {errors[navigator]}
            </div>}
        </div>
    )
}