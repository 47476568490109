import React from 'react'
import MadeWithLove from './MadeWithLove'
import { useRecoilValue } from 'recoil';
import { getText, languageState } from './../Utlities/app-store';
import { Link } from 'react-router-dom';
//position-absolute
export default function Footer({ className = "", color = "white", link = true }) {
    const lng = useRecoilValue(languageState)
    return (
        <footer className={`footer bottom-2 py-2 w-100 ${className}`}>
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-12 col-md-6">
                        <div className={`copyright text-center text-sm text-${color} text-lg-start`}>
                            {link ?
                                <Link to="/login" className={`text-${color}`}>
                                    {/* <i className="fa-solid fa-door-closed fs-6"></i> */}
                                    <span>{lng === "ar" ? "جميع الحقوق محفوظة لـ دار النخبة" : "All rights reserved to Daralnokba"} {new Date().getFullYear()}&copy;</span>
                                </Link>
                                : <span>{lng === "ar" ? "جميع الحقوق محفوظة لـ دار النخبة" : "All rights reserved to Daralnokba"} {new Date().getFullYear()}&copy;</span>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-auto">
                        <MadeWithLove color={color} />
                    </div>
                </div>
            </div>
        </footer>
    )
}
