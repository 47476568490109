import React, { useMemo, useState, useRef } from 'react'

export default function JustSelect({ propName, required, containerClassName, title, children, ...etc }) {
    const [isFoucsed, setIsFoucsed] = useState(false);
    const inpRef = useRef(null);
    return (
        <div className={`input-group input-group-outline mb-3${(isFoucsed || inpRef.current?.value ? ' is-focused' : '')}`}>
            <label className="form-label">{title} {required && <span className='mx-1 text-danger'>*</span>}</label>
            <select
                ref={inpRef}
                className="form-control"
                onFocus={() => setIsFoucsed(true)}
                onBlur={() => setIsFoucsed(inpRef.current.value != "")}
                {...etc}>
                {children}
            </select>
        </div>
    )
}