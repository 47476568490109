import React from 'react'
import YemenFlag from './YemenFlag';

export default function MadeWithLove({ color = "white" }) {
    return (

        <div className={`copyright text-center text-sm madeby text-${color} text-lg-end`}>
            <span className="ml-6">Made with</span> <i className="fa fa-heart" aria-hidden="true"></i> by
            <a href="https://twitter.com/AbuMaxeem" className={`font-weight-bold text-${color} mx-2`} target="_blank"><YemenFlag width={12} className="hover-effect" /> αϐυ мαϰєєм</a>
        </div>
    )
}
