import React from 'react'

export default function BModel({ show, setShow, theme = "primary", center = false, title, okText, cancelText, children, handleSubmit, size = "lg", bodyProps, isSubmitting = false }) {
    if (!show) return <></>;
    return (
        <div className="modal fade text-left show" style={{ display: 'block', zIndex: '990', backgroundColor: 'rgba(0,0,0,0.8)' }} aria-modal="true" role="dialog">
            <div className={`modal-dialog ${center && 'modal-dialog-centered'} modal-dialog-scrollable modal-${size == 'fl' ? 'fullscreen' : size}`} role="document">
                <div className="modal-content">
                    <div className={`modal-header${theme ? ` bg-${theme}` : ''}`}>
                        <h6 className="modal-title text-white">{title}</h6>
                        <button type="button" onClick={() => setShow(false)} disabled={isSubmitting} className="btn-close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" className="text-white" style={{ margin: "-1rem -1rem auto" }}>×</span></button>
                    </div>
                    <div className="modal-body" style={{ minHeight: "100px" }} {...bodyProps}>
                        {children}
                    </div>
                    <div className="modal-footer border-top">
                        <button type="button" className="btn btn-light" disabled={isSubmitting} onClick={() => setShow(false)}>{cancelText}</button>
                        {handleSubmit && <button type="button" className={`btn btn-${theme} btn-shadow`} disabled={isSubmitting} onClick={handleSubmit}>{okText}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}