import React, { useMemo, useReducer } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import FormInput from '../components/FormInput';
import FormCheckBox from '../components/FormCheckBox';
import { consts, getRapidTheme } from '../Utlities/functions';
import { getText, languageState } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';

//"Id", "Id as Ref", "NameArabic", "NameEnglish", "CountryArabic", "CountryEnglish"
const defaultValues = {
    Id: 0,
    NameArabic: '',
    NameEnglish: '',
    CountryArabic: '',
    CountryEnglish: ''
}
// form
const FormContent = () => {
    return (
        <>
            <div className="col-md-6"><FormInput maxLength={150} required propName="NameArabic" title={`${getText('officeName')} - ${getText('arabic')}`} /></div>
            <div className="col-md-6"><FormInput maxLength={150} propName="NameEnglish" title={`${getText('officeName')} - ${getText('english')}`} /></div>
            <div className="col-md-6"><FormInput maxLength={100} required propName="CountryArabic" title={`${getText('type0')} - ${getText('arabic')}`} /></div>
            <div className="col-md-6"><FormInput maxLength={100} propName="CountryEnglish" title={`${getText('type0')} - ${getText('english')}`} /></div>
        </>
    )
}
export default function OfficesPage() {
    const lng = useRecoilValue(languageState)
    const { validationSchema, columns, title } = useMemo(() => {
        // validation schema
        const validationSchema = Yup.object().shape({
            NameArabic: Yup.string().required(getText('requiredf')),
            CountryArabic: Yup.string().required(getText('requiredf')),
        })
        // table columns
        const columns = [
            {
                Header: getText('officeName'), accessor: 'NameArabic', isSorted: true, Cell: ({ value, row }) => <span>{value} - {row.original.NameEnglish ?? ''}</span>
            },
            { Header: getText('type0'), accessor: 'CountryArabic', Cell: ({ value, row }) => <span>{value} - {row.original.CountryEnglish ?? ''}</span> },
        ];
        const title = getText('offices')
        return { validationSchema, columns, title };
    }, [lng]);
    return (
        <DHead title={title}>
            <DefaultTable
                theme="primary"
                url="/api/offices"
                columns={columns}
                schema={validationSchema}
                FormContent={FormContent}
                defaultValues={defaultValues}
                editorProps={{
                    size: "lg"
                }}
                log

            />
        </DHead>
    )
}
