import { useFormikContext } from 'formik'
import React, { useMemo, useState, useRef } from 'react'

export default function JustInput({ propName, required, inputProps, containerClassName, title, type = "text", subText, children, ...etc }) {
    const inpRef = useRef(null);
    const [isFoucsed, setIsFoucsed] = useState(false);
    return (
        <div className={`input-group input-group-outline mb-3 ${(isFoucsed || inpRef.current?.value != "" ? 'is-focused' : '')}`}>
            <label className='form-label'>{title} {required && <span className='mx-1 text-danger'>*</span>}</label>
            <input ref={inpRef} type={type} className="form-control"
                onFocus={e => setIsFoucsed(true)}
                onBlur={e => setIsFoucsed(false)}
                {...etc}
            />
            {children}
        </div>
    )
}
