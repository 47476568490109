import React from 'react'

export default function DHead({ title, children, theme = "primary" }) {
    return (
        <>
            <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className={`bg-gradient-${theme} shadow-${theme} border-radius-lg pt-4 text-center p-3`}>
                        <h6 className="text-white text-capitalize ps-3">{title}</h6>
                    </div>
                </div>
                <div className="card-body px-0 pb-2">
                    {children}
                </div>
            </div>

            {/* <div className="section-header rtl">
                <h1>{title}</h1>
            </div>
            <div className="section-body">
                {children}
            </div> */}
        </>
    )
}
