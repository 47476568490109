import React from 'react'
import NokhbaLogo from './NokhbaLogo'
const Row = ({ en, ar, val }) => {
    return <tr>
        <th>{en}</th>
        <th colSpan={2} className='text-center'>{val}</th>
        <th className="text-end">{ar}</th>
    </tr>
}

export const ReportBody = ({ titleAr, titleEn, children }) => {
    return (
        <div className="container" style={{ minWidth: '700px' }} data-report-container>
            <div className="row">
                <h6 className="col-3 d-flex justify-content-center align-items-center">
                    {titleEn}
                </h6>
                <div className="col-6 d-flex justify-content-center flex-column align-items-center">
                    <NokhbaLogo height={90} />
                </div>
                <h6 className="col-3 d-flex justify-content-center align-items-center">
                    {titleAr}
                </h6>
            </div>
            <hr className='dark horizontal my-2' />
            {children}
        </div>
    )
}

export default function Report({ data }) {
    // console.log(data);

    return <ReportBody titleAr="إستمارة طلب إستقدام" titleEn="Application For Employment">
        <table className="table  border same-width">
            <tbody className="bold">
                {data.PersonId ? <Row ar="رقم السيرة الذاتية" en="CV#" val={data.PersonId} /> : null}
                <Row ar="الإسم" en="Name" val={data.Name} />
                <Row ar="رقم الهوية" en="Identity No." val={data.IdentityId} />
                <Row ar="تاريخ الميلاد" en="Dob" val={data.Dob} />
                <Row ar="رقم الهاتف" en="Phone" val={data.Phone} />
                {data.HasVisa ? <>
                    <Row ar="رقم الصادر" en="Visa Number" val={data.VisaNumber} />
                    <Row ar="تاريخ الصادر" en="Visa Date" val={data.VisaDate} />
                    <Row ar="رقم الحدود" en="Border No" val={data.BorderNo} />
                    <Row ar="جهة العمل" en="Work Place" val={data.WorkPlace} />
                    <Row ar="مدينة العمل" en="Work City" val={data.WorkCity} />
                    <Row ar="العنوان" en="Address" val={data.Address} />
                    <Row ar="اسم احد اقارب الدرجة الاولى" en="Name of First-Degree relative" val={data.RelativeName} />
                    <Row ar="صلة القرابة" en="Relative Type" val={data.RelativeType} />
                    <Row ar="تلفون القريب" en="Relative Phone" val={data.RelativePhone} />
                    <Row ar="جهة عمل الشخص القريب" en="Relative Work Place" val={data.RelativeWorkPlace} />
                    <Row ar="عدد ادوار المنزل" en="Floor Count" val={data.FloorCount} />
                    <Row ar="عدد غرف المنزل" en="Room Count" val={data.RoomsCount} />
                    <Row ar="عدد افراد الأسرة" en="Family Members Count" val={data.FamilyCount} />
                </> : null}
                <Row ar="ملاحظة" en="Note" val={data.Note} />
            </tbody>
        </table>
    </ReportBody>

}
