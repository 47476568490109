import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { getText, languageState } from '../Utlities/app-store'
import SAFlag from './SAFlag';
import GBFlag from './GBFlag';

export default function HeaderInfo() {
    const [lng, setLng] = useRecoilState(languageState);

    return (
        <div className="mt-sm-0 mt-2 me-md-0 me-sm-4 d-flex header-info" >
            <div className="btn-group btn-group-sm ml-6" role="group" aria-label="Basic example">
                <a target="_blank" title="تغيير اللغة | Change Language" href="https://instagram.com/dar_alnokhbah?igshid=NGExMmI2YTkyZg==" className="btn btn-info d-flex align-items-center"><i className="fa-brands fa-instagram fs-6"></i></a>
                <a target="_blank" href="https://snapchat.com/add/dar-alnokba" className="btn btn-info d-flex align-items-center"><i className="fa-brands fa-snapchat fs-6"></i></a>
                <button type="button" className="btn btn-info d-flex align-items-center justify-content-center" onClick={() => {
                    setLng(lng === "ar" ? "en" : "ar")
                }}>
                    {/* <SAFlag height="34" /> */}
                    {/* <GBFlag height="32" /> */}
                    {lng === "ar" ? "English" : "عربي"}</button>
            </div>
        </div>
    )
}

export function LoginPart() {
    const [lng, setLng] = useRecoilState(languageState);
    return (
        <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
            <button type="button" className="btn btn-dark-blue d-flex align-items-center justify-content-center" onClick={() => {
                setLng(lng === "ar" ? "en" : "ar")
            }}>
                {/* <SAFlag height="34" /> */}
                {/* <GBFlag height="32" /> */}
                {lng === "ar" ? "English" : "عربي"}</button>
            <Link className="btn btn-info d-flex align-items-center justify-content-center" to="/login">
                {getText('login')}
                {/* <i className="fa-solid fa-door-closed fs-6"></i> */}
            </Link>
        </div>
    )
}
