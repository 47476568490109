import React, { useEffect, useState } from 'react'
import { axiosClient, getText } from '../Utlities/app-store';
import { consts } from '../Utlities/functions';
import $ from 'jquery'

export default function OrderByOffice({ onChange }) {
    const [loaded, setLoaded] = useState(consts.offices && consts.offices.length > 0)
    const handleChange = () => {
        const officeId = $('#orderby').val();
        const showArchived = $('#showarchived').is(':checked')
        onChange({ officeId, showArchived })
    }
    useEffect(() => {
        if (!consts.offices) {
            axiosClient.get("/api/persons/prerequisite")
                .then(response => {
                    consts.offices = response.data.offices;
                }).finally(() => {
                    setLoaded(true)
                })
        }
    }, [])
    if (!loaded || !consts.offices || consts.offices.length == 0) return null
    return (
        <span className='d-fex'>
            <select id="orderby" onChange={handleChange} className="form-select border form-select-sm form-control form-control-sm d-inline-block" style={{ width: '200px' }}>
                <option value="">{getText('orderby') + ' ' + getText('offices')}</option>
                {consts.offices && consts.offices.length > 0 && consts.offices.map(item => <option value={item.Id} key={item.Id}>{getText(item.NameArabic, item.NameEnglish)}</option>)}
            </select>
            <div className="form-check d-inline-block mx-2"><input className="form-check-input mt-0" onChange={handleChange} id='showarchived' type="checkbox" /><label className="custom-control-label user-select-none mb-0" htmlFor="showarchived" style={{ margin: "0 6px" }}>{getText('showArchived')}</label></div>
        </span>
    )
}
