import React, { useState, useEffect, useMemo } from 'react'
import LoadingSpinner from './../components/LoadingSpinner';
import { axiosClient, getText, pickText } from '../Utlities/app-store';
import { DateTimeArabic, GetDashStastics, calculatePercentageDifference } from '../Utlities/functions';


const profileUrl = "https://st4.depositphotos.com/9998432/24360/v/450/depositphotos_243600690-stock-illustration-person-gray-photo-placeholder-girl.jpg";
export default function DashboardPage() {

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({
        last: [],
        stastics: undefined,
        lastUpdate: new Date()
    });
    const getData = () => {
        setIsLoading(true);
        axiosClient.get("/api/requests/dashboard").then(({ data }) => {
            setData({ ...data, lastUpdate: new Date() })
        }).finally(() => {
            setIsLoading(false);
        })
    }
    const stastics = useMemo(() => {
        //int today = 0, yesterday = 0, week = 0, lastweek = 0, month = 0, lastmonth = 0, months = 0;
        if (!data || !data.stastics) return null;
        return (
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                                <i className="material-icons opacity-10">D</i>
                            </div>
                            <div className="text-end pt-1">
                                <p className="text-sm mb-0 text-capitalize">{getText('todaysrequests')}</p>
                                <h4 className="mb-0">{data.stastics.today}</h4>
                            </div>
                        </div>
                        <hr className="dark horizontal my-0" />
                        <div className="card-footer p-3">
                            <p className="mb-0">
                                <Percent value1={data.stastics.today} value2={data.stastics.yesterday} />
                                {getText('thanyesterday')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                                <i className="material-icons opacity-10">W</i>
                            </div>
                            <div className="text-end pt-1">
                                <p className="text-sm mb-0 text-capitalize">{getText('weekrequests')}</p>
                                <h4 className="mb-0">{data.stastics.week}</h4>
                            </div>
                        </div>
                        <hr className="dark horizontal my-0" />
                        <div className="card-footer p-3">
                            <p className="mb-0"><Percent value1={data.stastics.week} value2={data.stastics.lastweek} /> {getText('thanlastweek')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                <i className="material-icons opacity-10">1M</i>
                            </div>
                            <div className="text-end pt-1">
                                <p className="text-sm mb-0 text-capitalize">{getText('monthrequests')}</p>
                                <h4 className="mb-0">{data.stastics.month}</h4>
                            </div>
                        </div>
                        <hr className="dark horizontal my-0" />
                        <div className="card-footer p-3">
                            <p className="mb-0">
                                <Percent value1={data.stastics.month} value2={data.stastics.lastmonth} />
                                {getText('thanlastmonth')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-sm-6">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                <i className="material-icons opacity-10">2M</i>
                            </div>
                            <div className="text-end pt-1">
                                <p className="text-sm mb-0 text-capitalize">{getText('twomonthrequests')}</p>
                                <h4 className="mb-0">{data.stastics.months}</h4>
                            </div>
                        </div>
                        <hr className="dark horizontal my-0" />
                        <div className="card-footer p-3">
                            <p className="mb-0" style={{ color: "transparent" }}><span className="">+0% </span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [data])
    useEffect(() => {
        setTimeout(() => {
            getData();
        }, 500)
    }, [])

    // return <div style={{ minHeight: "80vh" }} className='d-flex justify-content-center align-items-center'>
    //     <p>الصفحة الرئيسية</p>
    // </div>
    if (isLoading) return <LoadingSpinner />
    return (
        <>

            {stastics}
            {/* <div className="row mt-4">
                <div className="col-lg-4 col-md-6 mt-4 mb-4">
                    <div className="card z-index-2 ">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                            <div className="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                                <div className="chart">
                                    <canvas id="chart-bars" className="chart-canvas" height="170" width="488" styles="display: block; box-sizing: border-box; height: 170px; width: 488.7px;"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="mb-0 ">Website Views</h6>
                            <p className="text-sm ">Last Campaign Performance</p>
                            <hr className="dark horizontal" />
                            <div className="d-flex ">
                                <i className="material-icons text-sm my-auto me-1">schedule</i>
                                <p className="mb-0 text-sm"> campaign sent 2 days ago </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mt-4 mb-4">
                    <div className="card z-index-2  ">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                            <div className="bg-gradient-success shadow-success border-radius-lg py-3 pe-1">
                                <div className="chart">
                                    <canvas id="chart-line" className="chart-canvas" height="170" width="488" styles="display: block; box-sizing: border-box; height: 170px; width: 488.7px;"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="mb-0 "> Daily Sales </h6>
                            <p className="text-sm "> (<span className="font-weight-bolder">+15%</span>) increase in today sales. </p>
                            <hr className="dark horizontal" />
                            <div className="d-flex ">
                                <i className="material-icons text-sm my-auto me-1">schedule</i>
                                <p className="mb-0 text-sm"> updated 4 min ago </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4 mb-3">
                    <div className="card z-index-2 ">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                            <div className="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1">
                                <div className="chart">
                                    <canvas id="chart-line-tasks" className="chart-canvas" height="170" width="488" styles="display: block; box-sizing: border-box; height: 170px; width: 488.7px;"></canvas>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <h6 className="mb-0 ">Completed Tasks</h6>
                            <p className="text-sm ">Last Campaign Performance</p>
                            <hr className="dark horizontal" />
                            <div className="d-flex ">
                                <i className="material-icons text-sm my-auto me-1">schedule</i>
                                <p className="mb-0 text-sm">just updated</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row mb-4 mt-5">
                <div className="col-12 mb-md-0 mb-4">
                    <div className="card">
                        <div className="card-header pb-0 bg-gradient-primary">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h6>{getText('last10')}</h6>
                                </div>
                                <button className="btn btn-sm btn-info" onClick={() => getData()}>
                                    {getText('update')}
                                </button>
                            </div>
                        </div>
                        <div className="card-body px-0 pb-2">
                            <div className="table-responsive">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{getText('clientName')}</th>
                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">{getText('empName')}</th>
                                            <th className=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{getText('requestdate')}</th>
                                            <th className=" text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{getText('requeststatus')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.last.length === 0 ? <tr><td colSpan={4} className='text-center'>{getText('nodatafound')}</td></tr> : data.last.map(c => <tr key={c.Id}>
                                            <td><span className="text-xs px-2 py-1 font-weight-bold">{c.By}</span></td>
                                            <td><span className="text-xs px-2 py-1 font-weight-bold">{c.Person ?? ""}</span></td>
                                            <td><span className="text-xs px-2 py-1 font-weight-bold">{DateTimeArabic(c.RequestDate)}</span></td>
                                            <td><span className="text-xs px-2 py-1 font-weight-bold">{c.FilterId ? pickText(c.FilterAr, c.FilterEn) : ""}</span></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

const Percent = ({ value1, value2 }) => {
    const val = calculatePercentageDifference(value1, value2);
    return (
        <span className={`text-${(val > 0 ? "success" : "danger")} text-sm font-weight-bolder`}>{val}% </span>
    )
}
