
//url="/content/makarem.jpg" to=

import React, { useEffect, useState } from 'react';
import $ from 'jquery'; // Import jQuery

const Banner = ({ url, to }) => {
  return (
    <div className="container my-4">
      {/* Ad section */}
      <div className="position-relative overflow-hidden hover-effect">
        <a href="https://www.makarem-ksa.com/" target="_blank" className="card border-0">
          <img
            src="/content/makarem.jpg"
            className="card-img-top img-fluid"
            alt="Ad"
          />
        </a>
        <div className='position-absolute text-center  d-flex justify-content-center align-items-center' style={{top:0,right:"25%",bottom:0,left:0}}>

          <AnimatedTextJQuery/>
        </div>
      </div>
    </div>
  );
};

export default Banner;



const AnimatedTextJQuery = () => {
  const strings = [
    `تقديم ابتكارات عالية الجودة تناسب جميع الأذواق`,
     `تقديم تجربة فريدة مليئة بالتميز والإبداع`, 
     `دمج الخدمات المقدمة في فنون الضيافة العالمية مع الطابع السعودي العريق`];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const animateText = () => {
      // Get the current text element
      const $currentText = $(`#text-${currentIndex}`);
      // Fade out the current text
      $currentText.fadeOut('fast', () => {
        // Update the index after fade out
        const nextIndex = (currentIndex + 1) % strings.length;
        setCurrentIndex(nextIndex);

        // Fade in the next text after index is updated
        $(`#text-${nextIndex}`).fadeIn('fast').delay(2000).fadeOut('fast');
      });
    };

    // Run the animation on component mount
    animateText();

    // Set interval for continuous animation
    const interval = setInterval(animateText, 4000); // 3 seconds

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, [currentIndex, strings.length]);

  return (
    <div style={{fontSize:"1.6vw"}}>
      {strings.map((text, index) => (
        <div
        className='text-center text-dark'
          key={index}
          id={`text-${index}`}
          style={{ display: index === 0 ? 'inline' : 'none' }} // Show the first one by default
        >
          {text}
        </div>
      ))}
    </div>
  );
};

