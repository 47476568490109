import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil';
import { axiosClient, getText, userState } from '../Utlities/app-store';
import { Wait } from 'fnon'
export default function Auth({ children }) {
    const setUser = useSetRecoilState(userState);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        Wait.Bricks(getText('pleasewait'));
        const fetchData = async () => {
            try {
                // Send a request to the server to check if the user is authenticated
                const response = await axios.get('/api/login/isauth', { withCredentials: true });
                setUser(response.data);
            } catch (error) {
                // Handle unauthorized access or other errors
                // console.error('Error fetching user:', error);
                setUser(null);
            } finally {
                setLoading(false);
                Wait.Remove();
            }
        };
        fetchData();
        // Set Error Interceptor
        axiosClient.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            if (error.response && error.response.status === 401) {
                axiosClient.get('/api/login/jwtout').finally(() => {
                    setUser(null);
                })
            } else {
                return Promise.reject(error);
            }
        });
    }, [])

    if (loading) return null
    return children;
}
