import $ from "jquery";
import html2canvas from "html2canvas";

export function getRapidTheme(index) {
  // Define an array of values
  const values = ["primary", "secondary", "info", "dark", "success", "warning"];

  // Calculate the index based on the length of the array
  const calculatedIndex = index % values.length;

  // Return the value at the calculated index
  return values[calculatedIndex];
}

export const ShallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const consts = {
  user: undefined,
  /**
   *
   * @param {Array} rolesArray an array of strings
   * @returns
   */
  isInRole: (rolesArray) => {
    if (consts.user) {
      return rolesArray.includes(consts.user.roles[0]);
    }
    return false;
  },
  /**
   * It checkes wether user is Editor
   * @returns Returns true or false
   */
  isEditor: () => consts.isInRole(["Editor", "Admin", "Developer"]),
  /**
   * It checks wether user is an Admin
   * @returns Returns true or false
   */
  isAdmin: () => consts.isInRole(["Admin", "Developer"]),
  /**
   *
   */
  setShowSpinner: null,
  filters: null,
  dashboard: [],
  offices: null,
  // getText: (name) => {},
  // pickText: (ar, en) => {},
};

export const CheckCell = ({ value }) =>
  value ? (
    <i className="fa-solid fa-check"></i>
  ) : (
    <i className="fa-solid fa-xmark"></i>
  );

export const DateCell = ({ value }) => {
  return value ? (
    <>
      {DateTimeArabic(value)}
      <br /> {HijriDate(value)}
    </>
  ) : null;
};
export const DefaultDateCell = ({ value }) => {
  if (value) {
    return new Date(value).toLocaleDateString();
  }
  return null;
};
export function updateDateProperties(obj) {
  for (let prop in obj) {
    if (typeof obj[prop] === "object" && obj[prop] !== null) {
      updateDateProperties(obj[prop]); // Recursively call the function for nested objects
    } else if (obj[prop] instanceof Date) {
      obj[prop] = DateToInputeDate(obj[prop]);
    } else if (typeof obj[prop] === "string") {
      const parsedDate = new Date(obj[prop]);
      if (!isNaN(parsedDate.getTime())) {
        obj[prop] = DateToInputeDate(parsedDate);
      }
    }
  }
}
export const DateToInputeDate = (date) => {
  if (!date) return "";
  if (typeof date === "string") {
    // Check if the input is already a string representation of a date
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      // If the parsed date is invalid, return the original input
      return date;
    }
    // If the parsed date is valid, assign it to the date variable
    date = parsedDate;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function ResizeImages(file, complete) {
  // read file as dataUrl
  ////////  2. Read the file as a data Url
  var reader = new FileReader();
  // file read
  reader.onload = function (e) {
    // create img to store data url
    ////// 3 - 1 Create image object for canvas to use
    var img = new Image();
    img.onload = function () {
      /////////// 3-2 send image object to function for manipulation
      complete(resizeInCanvas(img));
    };
    img.src = e.target.result;
  };
  // read file
  reader.readAsDataURL(file);
}
function resizeInCanvas(img) {
  /////////  3-3 manipulate image
  var perferedWidth = 600;
  var ratio = perferedWidth / img.width;
  var canvas = $("<canvas>")[0];
  canvas.width = img.width * ratio;
  canvas.height = img.height * ratio;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  //////////4. export as dataUrl
  return canvas.toDataURL();
}
export const ClearBase64Url = (url) => {
  const b = ";base64,";
  return url.substring(url.indexOf(b) + b.length, url.length);
};

export function maxLen(max) {
  return `لايمكن ان يكون عدد احرف الحقل اكثر من ${max} حرف`;
}

export const orderStatus = [
  "تم إرسال الطلب",
  "الطلب تحت الدراسة",
  "مطلوب بيانات إظافية",
  "تمت الموافقة",
  "تم إيداع المبلغ",
  "إنتظار التسديد",
  "تم التسديد",
  "مكتمل",
  "ملغي",
  "مرفوض",
];
/**
 * Get the arabic transalation of a status
 * @param {number} status
 */
export function OrderTypeTransaltion(status) {
  return orderStatus[status];
}
/**
 * Get bootstrap theme
 * @param {number} status
 */
export function StatusTheme(status) {
  if ([0, 1, 2].includes(status)) return "warning";
  else if ([3].includes(status)) return "info";
  else if ([4, 5, 6, 7].includes(status)) return "success";
  else if ([8, 9].includes(status)) return "danger";
  return "";
}

export function GroupArrayByYearMonth(array) {
  const groupsByYear = {};

  array.forEach((item) => {
    let date = item.Date;

    if (typeof date === "string") {
      date = new Date(date);
    }

    const year = date.getFullYear();

    if (!groupsByYear[year]) {
      groupsByYear[year] = {};
    }

    const month = date.getMonth();

    if (!groupsByYear[year][month]) {
      groupsByYear[year][month] = [];
    }

    groupsByYear[year][month].push(item);
  });

  const result = Object.keys(groupsByYear).map((year) => {
    const months = groupsByYear[year];
    const yearResult = {
      year: parseInt(year),
      months: [],
    };

    Object.keys(months).forEach((month) => {
      const monthItems = months[month];
      const date = new Date(parseInt(year), parseInt(month));

      yearResult.months.push({
        month: parseInt(month),
        date,
        items: monthItems,
      });
    });

    return yearResult;
  });

  return result;
}

export function groupBy(array, key) {
  const groupedData = array.reduce((result, obj) => {
    const groupKey = obj[key];

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(obj);
    return result;
  }, {});

  return Object.entries(groupedData).map(([key, value]) => ({
    key,
    value,
  }));
}

// function name(params) {
//   "len",
//     "can be empty or with string at least 2 characters and not more than 10",
//     (val) => {
//       if (val === undefined) {
//         return true;
//       }
//       return val.length === 0 || (val.length >= 2 && val.length <= 10);
//     }
// }

export function DateTimeArabic(date, locales = "ar-SA") {
  try {
    return new Intl.DateTimeFormat(locales, {
      dateStyle: "full",
      timeStyle: "short",
      calendar: "gregory",
      numberingSystem: "arab",
    }).format(new Date(date));
  } catch (error) {
    return new Date(date).toLocaleString();
  }
}
export function HijriDate(date) {
  return new Intl.DateTimeFormat("ar-TN-u-ca-islamic", {
    day: "numeric",
    month: "long",
    weekday: "long",
    year: "numeric",
  }).format(new Date(date));
}
export function calculatePercentageDifference(currentValue, previousValue) {
  // if (!currentValue || !previousValue) return "??";
  if (previousValue === 0) {
    return currentValue === 0 ? 0 : 100; // Avoid division by zero
  }

  const percentageDifference =
    ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
  return percentageDifference.toFixed(2);
}

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];
const formatter = new Intl.RelativeTimeFormat("ar-SA", {
  numeric: "auto",
});
/**
 *
 * @param {Date} date
 */
export function FormatTimeAge(date) {
  let duration = (new Date(date) - new Date()) / 1000;
  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

export function PrintElement(element) {
  html2canvas(element).then((canvas) => {
    var dataUrl = canvas.toDataURL(); //attempt to save base64 string to server using this var
    // Create a new document
    var newDocument = window.open("", "_blank");

    // Copy the current head to the new document
    // newDocument.document.head.innerHTML = document.head.innerHTML;

    // Append the received HTML string to the new document's body
    newDocument.document.body.innerHTML = '<img src="' + dataUrl + '">';
    newDocument.document.title = "Please wait...";
    // newDocument.onafterprint = () => newDocument.close();
    newDocument.onafterprint = () => {
      newDocument.close();
    };
    newDocument.onload = () => {
      setTimeout(() => {
        newDocument.print();
      }, 900);
    };
  });
}

export function GetDashStastics(data) {
  // Get the current date
  const currentDate = new Date();

  // Filter records for today
  const todayRecords = data.filter((record) => {
    const recordDate = new Date(record.RequestDate);
    return (
      recordDate.getDate() === currentDate.getDate() &&
      recordDate.getMonth() === currentDate.getMonth() &&
      recordDate.getFullYear() === currentDate.getFullYear()
    );
  });

  // Filter records for one week ago
  const oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  const oneWeekRecords = data.filter((record) => {
    const recordDate = new Date(record.RequestDate);
    return recordDate >= oneWeekAgo && recordDate <= currentDate;
  });

  // Filter records for two weeks ago
  const twoWeeksAgo = new Date(currentDate);
  twoWeeksAgo.setDate(currentDate.getDate() - 14);
  const twoWeeksRecords = data.filter((record) => {
    const recordDate = new Date(record.RequestDate);
    return recordDate >= twoWeeksAgo && recordDate < oneWeekAgo;
  });

  // Filter records for one month ago
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  const oneMonthRecords = data.filter((record) => {
    const recordDate = new Date(record.RequestDate);
    return recordDate >= oneMonthAgo && recordDate < currentDate;
  });

  return {
    today: todayRecords.length,
    oneWeek: oneWeekRecords.length,
    twoWeeks: twoWeeksRecords.length,
    oneMonth: oneMonthRecords.length,
  };
}

export function GetDaysDifference(startDate, endDate) {
  // Convert both dates to milliseconds
  var startMilliseconds = startDate.getTime();
  var endMilliseconds = endDate.getTime();

  // Calculate the difference in milliseconds
  var timeDifference = endMilliseconds - startMilliseconds;

  // Convert the difference from milliseconds to days
  var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export function DateToInputDate(date) {
  const y = date.getFullYear();
  const m = date.getMonth().toString().padStart(2, "0");
  const d = date.getDay().toString().padStart(2, "0");
  return y + "-" + m + "-" + d;
}
