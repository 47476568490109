import React, { useState, useEffect, useMemo } from 'react'
import DHead from '../components/DHead'
import DefaultTable from '../components/DTable';
import * as Yup from "yup"
import LoadingSpinner from '../components/LoadingSpinner';
import { DateCell, DefaultDateCell, consts, PrintElement, ConvertDivToImageAndPrint, GetDaysDifference } from '../Utlities/functions';
import $ from "jquery"
import { axiosClient, getText, languageState, pickText } from '../Utlities/app-store';
import { useRecoilValue } from 'recoil';
import { RequestForm } from './ClientPage';
import BModel from '../components/BModel';
import Report from '../components/Report';
import FormInput from '../components/FormInput';
import FormTextArea from '../components/FormTextArea';
import FormSelect from '../components/FormSelect';
import axios from 'axios';
import { Wait } from 'fnon'
import OrderByOffice from '../components/OrderByOffice';
// import { PrintElement } from '../Utlities/printForm';
// validation schema
const showFollow = ({ currentTarget }) => {
    if ($(currentTarget).hasClass('cursor-pointer')) {
        $(currentTarget).removeClass('cursor-pointer')
        Wait.Bricks(getText('pleasewait'))
        setTimeout(() => {

            //console.log($(currentTarget).closest('.d-flex').nextAll('.d-none'))
            $(currentTarget).closest('.d-flex').nextAll('.d-none').removeClass('d-none')
            Wait.Remove();
        }, (Math.floor((Math.random()) * (500 - 200 + 1)) + 200))
    }
}

const FollowContent = ({ className = "" }) => {
    return <>
        <div className={`col-md-6 ${className}`}>
            <FormSelect propName="FilterId" title={getText("followStatus")}>
                <option></option>
                {consts.filters.filter(c => c.Type === 6).map(item => <option key={item.Id} value={item.Id}>{pickText(item.Ar, item.En)}</option>)}
            </FormSelect>
        </div>
        <div className={`col-md-6 ${className}`}><FormInput propName="BoloEntered" type="date" title={getText("boloEntered")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="BoloRecived" type="date" title={getText("boloRecived")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="PaidMusand" type="date" title={getText("paidMusand")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="LinkMusand" type="date" title={getText("linkMusand")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="SignMusand" type="date" title={getText("signMusand")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="ContractNo" title={getText("contractNo")} /></div>
        {/* <div className={`col-md-6 ${className}`}><FormInput propName="Contract" type="date" title={getText("contractDate")} /></div> */}
        <div className={`col-md-6 ${className}`}><FormInput propName="Medical" title={getText("Medical")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="Biometric" title={getText("Biometric")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="OWWA" title={getText("OWWA")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="OEC" title={getText("OEC")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="Egency" type="date" title={getText("agency")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="EnteredEmbessy" type="date" title={getText("embessyEntered")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="VisaRecived" type="date" title={getText("visaRecived")} /></div>
        <div className={`col-md-6 ${className}`}><FormInput propName="Travel" type="date" title={getText("travelDate")} /></div>
        <div className={`col-12 ${className}`}><FormTextArea propName="FollowUpNote" title={getText("followNote")} /></div>
    </>
}
const FormContent = (props) => {
    return (
        <>
            <ChangeWorker {...props} />
            <RequestForm values={props.values} key="requests" showArchive={true} />
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1"><hr className='dark horizontal my-2' /></div>
                <div className='px-2 py-1 bg-primary text-white cursor-pointer' onClick={showFollow}>
                    {getText('followupSection')}
                </div>
                <div className="flex-grow-1"><hr className='dark horizontal my-2' /></div>
            </div>
            <FollowContent className="d-none" />
        </>
    )
}

const ChangeWorker = React.memo(({ values, setFieldValue }) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    function loadData(showValue = true) {
        setLoading(true);
        $.ajax("/api/persons/getdata").then(data => {
            setData(data);
            setShow(showValue)
        }).catch(() => setData([])).always(() => setLoading(false));
    }
    return (
        <>
            <div className="col-9">
                <div className="bg-gradient-info text-white p-2 rounded">
                    <div className="text-center">{values.Person ? (getText('employee') + " : " + values.Person?.Name + `    (#${values.PersonId})`) : "لم يتم تحديد العامل بعد"}</div>
                </div>
            </div>
            <div className="col-3">
                <div className="btn-group btn-group-sm">
                    <button type="button" className='btn btn-danger' onClick={() => {
                        setFieldValue('PersonId', "")
                        setFieldValue('Person', null)
                    }}>{getText('cancel')}</button>
                    <button type="button" className='btn btn-warning' disabled={loading} onClick={() => {
                        if (data.length > 0) {
                            setShow(true);
                        } else {
                            loadData()
                        }
                    }}>{getText('setemployee')}</button>
                </div>
            </div>
            <hr className='dark horizontal my-4 col-12' />
            {show &&
                <BModel show={show} setShow={setShow} size='xl' title={getText('setemployee')} cancelText={getText("cancel")}>
                    <div className="row">
                        {data.map(item => <div className='col-md-3 col-sm-2' key={item.Id}>
                            <div className="card bg-dark text-white hover-effect scale" onClick={() => {
                                setShow(false);
                                setFieldValue('PersonId', item.Id)
                                setFieldValue('Person', item)
                            }} title='إضغط للإختيار'>
                                <img src={`/content/` + item.Photo} className="card-img" alt="..." />
                            </div>
                        </div>)}
                    </div>
                </BModel>
            }
        </>
    )
})
const tableChanged = () => {
    $('tr.green,tr.orange,tr.red').removeClass('green red orange')
    setTimeout(() => {
        $('td span.green').closest('tr').addClass('green');
        $('td span.orange').closest('tr').addClass('orange');
        $('td span.red').closest('tr').addClass('red');
    }, 300)
};

const childFunction = {
    tableChanged,
    ajaxTable: {}
};
const rowCss = ({ original }) => {
    if (original.PaidMusand) {
        const dif = GetDaysDifference(new Date(original.PaidMusand), new Date())
        if (dif > 20 && dif <= 40) return "green";
        if (dif > 40 && dif <= 60) return "orange";
        if (dif > 60) return "red";
    }
    //console.log(typeof (original.PaidMusand))
    return "";
}
const RenderFollowStatusCell = ({ value, row }) => {
    if (row && row.original && row.original.Filter) {
        return <span>{getText(row.original.Filter.Ar, row.original.Filter.En)}</span>
    }
    return null;
}
//const childFunction={};
export default function RequestsPage({ isFollow = false }) {

    const [loaded, setLoaded] = useState(false);
    // const [fetchDataAjax, , setFetchDataAjax] = useState({});
    const [report, setReport] = useState(null);
    const [employee, setEmployee] = useState(null);
    const lng = useRecoilValue(languageState)
    useEffect(() => {
        childFunction.ajaxTable = {};
        if (!loaded) {
            const axiosRequests = [];

            if (!consts.filters) {
                axiosRequests.push(
                    axiosClient.get("/api/filters/getdata")
                        .then(response => {
                            consts.filters = response.data;
                        })
                        .catch(error => {
                            console.error("Error fetching filters:", error);
                        })
                );
            }

            // if (!consts.offices) {
            //     axiosRequests.push(
            //         axiosClient.get("/api/persons/prerequisite")
            //             .then(response => {
            //                 consts.offices = response.data.offices;
            //             })
            //             .catch(error => {
            //                 console.error("Error fetching offices:", error);
            //                 // Optionally handle the error here
            //             })
            //     );
            // }

            if (axiosRequests.length > 0) {
                axios.all(axiosRequests)
                    .then(() => {
                        setLoaded(true);
                    })
                    .catch(error => {
                        console.error("An error occurred during Axios requests:", error);
                        // Optionally handle the error here
                    });
            } else {
                setLoaded(true);
            }
        }
    }, [])
    const { validationSchema, shortValidationSchema } = useMemo(() => {
        {
            const rf = getText("requiredf");
            const ff = Yup.string().required(rf).matches(
                /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                getText('invalidphone')
            );
            const reqifstring = Yup.string().test("test", rf, (value, context) => {
                return !context.parent.HasVisa || (context.parent.HasVisa && value?.length > 0);
            })
            const reqifnumber = Yup.number().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).min(1)
            });
            const reqifphone = Yup.string().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).matches(
                    /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                    getText('invalidphone')
                )
            })
            const validationSchema = Yup.object({
                Name: Yup.string().required(rf),
                IdentityId: Yup.string()
                    .required(rf)
                    .max(10, getText('identitymax'))
                    .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
                Dob: Yup.string().required(rf),
                Phone: ff,
                HasVisa: Yup.boolean(),
                VisaNumber: reqifstring,
                VisaDate: Yup.date().when("HasVisa", {
                    is: true,
                    then: schema => schema.required(rf)
                }),
                BorderNo: reqifstring,
                WorkPlace: reqifstring,
                WorkCity: reqifstring,
                RelativeName: reqifstring,
                RelativePhone: reqifphone,
                RelativeWorkPlace: reqifstring,
                RelativeType: reqifstring,
                Address: reqifstring,
                FloorCount: reqifnumber,
                RoomsCount: reqifnumber,
                FamilyCount: reqifnumber,
                // BorderNo: Yup.string().test("test", rf, (value, context) => {
                //     return !(context.parent.HasVisa === true) || (context.parent.HasVisa && value?.length > 0);
                // })
            })
            const shortValidationSchema = Yup.object({
                Name: Yup.string().required(rf),
                IdentityId: Yup.string()
                    .required(rf)
                    .max(10, getText('identitymax'))
                    .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
                Dob: Yup.string().required(rf),
                Phone: ff,
                HasVisa: Yup.boolean(),
            })
            return { validationSchema, shortValidationSchema }
        }
    }, [lng])

    const { columns, title, theme } = useMemo(() => {
        //"Id", "Id as Ref", "Name", "RequestDate", "Dob", "IdentityId", "HasVisa", "VisaNumber", "VisaDate", "Phone","WorkPhone", "WorkPlace", "RelativeName", "RelativePhone", "RelativeWorkPlace", "Address", "Note", "FloorCount", "RoomsCount", "PersonId", "Status"
        const columns = [
            { Header: getText('ref'), accessor: 'Ref', disableSortBy: true, Cell: ({ value, row }) => <span className={rowCss(row)}>{value}</span> },
            { Header: getText('clientName'), accessor: 'Name', isSorted: true },
            {
                Header: getText('employee'), accessor: 'Person', disableSortBy: true, Cell: ({ value }) => {
                    if (!value) return "";
                    return (
                        <div onClick={() => setEmployee(value)} className='hover-effect'>#{value.Id} - {value.Name}</div>
                    )
                }
            }
        ];
        if (!isFollow) {
            columns.push({ Header: getText('requestdate'), accessor: 'RequestDate', Cell: DateCell },
                {
                    Header: getText('requeststatus'), accessor: 'Status', Cell: ({ value }) => {
                        const stat = consts.filters.find(c => c.Id === value);
                        if (stat) return pickText(stat.Ar, stat.En)
                        return "";
                    }
                }, {
                Header: "", accessor: 'HasVisa', disableSortBy: true, Cell: ({ value, row }) => <button className='btn btn-success btn-sm py-1' onClick={() => {
                    setReport(row.original)
                }}><i className="fa-solid fa-print mr-6 fs-6"></i>{getText('print')}</button>
            });
        } else {
            //"FilterId", "BoloEntered", "BoloRecived", "PaidMusand", "LinkMusand", "SignMusand",  "ContractNo", "Medical", "Biometric", "OWWA", "OEC", "Egency", "EnteredEmbessy", "VisaRecived", "Travel", "FollowUpNote", "Filter"
            columns.push(
                { Header: getText('linkMusand'), accessor: 'LinkMusand', Cell: DefaultDateCell },
                { Header: getText('paidMusand'), accessor: 'PaidMusand', Cell: DefaultDateCell },
                { Header: getText('contractNo'), accessor: 'ContractNo', Cell: DefaultDateCell },
                { Header: getText('boloEntered'), accessor: 'BoloEntered', Cell: DefaultDateCell },
                { Header: getText('boloRecived'), accessor: 'BoloRecived', Cell: DefaultDateCell },
                { Header: getText('inEmbessy'), accessor: 'EnteredEmbessy', Cell: DefaultDateCell },
                { Header: getText('gotVisa'), accessor: 'VisaRecived', Cell: DefaultDateCell },
                { Header: getText('travelDate'), accessor: 'Travel', Cell: DefaultDateCell },
                { Header: getText('auth'), accessor: 'Egency', Cell: DefaultDateCell },
                { Header: "BIO", accessor: 'Biometric' },
                { Header: "OWWA", accessor: 'OWWA' },
                { Header: "OEC", accessor: 'OEC' },
                { Header: getText('note'), accessor: 'FollowUpNote' },
                { Header: getText('status'), accessor: 'FilterId', Cell: RenderFollowStatusCell },
            )
        }
        const title = getText(isFollow ? "followupSection" : "requests");
        const theme = isFollow ? "dark" : "info";
        return { columns, title, theme }
    }, [lng])
    if (!loaded) return <LoadingSpinner />
    return (
        <>
            <DHead title={title} theme={theme}>
                <DefaultTable
                    url={`/api/${isFollow ? 'follow' : 'requests'}`}
                    columns={columns}
                    theme={theme}
                    title={title}
                    schema={isFollow ? null : shortValidationSchema}
                    FormContent={isFollow ? FollowContent : FormContent}
                    // fetchDataAjax={fetchDataAjax}
                    defaultValues={{
                        Id: 0,
                        Name: "",
                        IdentityId: "",
                        Dob: "",
                        HasVisa: false,
                        VisaNumber: "",
                        VisaDate: "",
                        BorderNo: "",
                        Phone: "",
                        WorkPlace: "",
                        WorkCity: "",
                        RelativeName: "",
                        RelativePhone: "",
                        RelativeWorkPlace: "",
                        Address: "",
                        FloorCount: 1,
                        RoomsCount: 1,
                        FamilyCount: 1,
                        Status: ""
                    }}
                    // filterComponenets={<select onChange={e => {
                    //     childFunction.refresh({ officeId: e.currentTarget.value })
                    // }} className="form-select border form-select-sm form-control form-control-sm d-inline-block" style={{ width: '200px' }}>
                    //     <option>{getText('orderby') + ' ' + getText('offices')}</option>
                    //     {consts.offices && consts.offices.length > 0 && consts.offices.map(item => <option value={item.Id} key={item.Id}>{getText(item.NameArabic, item.NameEnglish)}</option>)}
                    // </select>}
                    filterComponenets={<OrderByOffice onChange={vals => {
                        childFunction.ajaxTable = vals;
                        childFunction.refresh()
                    }} />}
                    editorProps={{
                        size: "xl"
                    }}
                    // tabInfo={{ getTrProps }}
                    log={!isFollow}
                    hideDeleteButton={isFollow}
                    hideAddButton={isFollow}
                    childFunction={childFunction}

                // onRowClicked={onRowClicked}
                />
            </DHead>
            {!isFollow && <>
                {employee &&
                    <BModel title={getText('employeeinfo')} cancelText={getText('close')} show={employee} setShow={() => setEmployee(null)} size='xl'>
                        <div className='row'>
                            <div className="col-md-3 col-sm-6 text-end border-bottom">#{getText('ref')}</div>
                            <div className="col-md-3 col-sm-6 text-center border-bottom">{employee.Id}</div>
                            <div className="col-md-3 col-sm-6 text-end border-bottom">#{getText('name')}</div>
                            <div className="col-md-3 col-sm-6 text-center border-bottom" >{employee.Name}</div>
                            <div className="col-12">
                                <img src={`/content/${employee?.Photo}`} width="100%" alt="" />
                            </div>
                        </div>
                    </BModel>}
                {report && <BModel title={getText('preview')} okText={getText('print')} cancelText={getText('close')} handleSubmit={() => {
                    //PrintElement
                    PrintElement(document.querySelector('[data-report-container]'))

                }} show={report} setShow={(() => {
                    setReport(null);
                })}>
                    <Report data={report} setData={setReport} />
                </BModel>}</>}
        </>
    )
}
